import React, { Component } from "react";
import Proptypes from "prop-types";

import "./Notify.less";

import notifyImg from "../images/notify.png";

import Wrap from "./Wrap";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {};

  render() {
    return (
      <Wrap {...this.props}>
        <div className="notify">
          <img src={notifyImg} />
        </div>
      </Wrap>
    );
  }
}

export default Modal;

import remote from "./request";
import { info as infoStorage } from "@/util/storageManager";

export const getEnableShopAPI = async () => {
    const url = `/member/car/getEnableShop.do`;
    return await remote.post(url);
};

export const getNearestShopAPI = async ({ longitude, latitude }) => {
    const url = `/member/car/getNearestShop.do`;
    return await remote.get(url, { longitude, latitude });
};

// city curPage pageSize
export const getCityShopAPI = async () => {
    const url = `/member/car/getEnableShop.do`;
    return await remote.post(url)
};

export const carListAPI = async () => {
    const url = "/member/car/list.do";
    return await remote.post(url, { body: {}, isNeedMemberPhone: true });
};

// String carNumber, String shopNo, String memberPhone
export const carFeeRecordAPI = async ({ shopNo, carNumber }) => {
    const url = `/member/car/feeRecord.do`;
    const json = await remote.get(url, { shopNo, carNumber, isNeedMemberPhone: true, showErrToast: false });
    return json;
};

export const carFeePreViewAPI = async ({ carNumber }) => {
    const url = `/keyTop/queryCarDeductInfo`;
    const json = await remote.post(url, { body: { plateNo: carNumber }, isNeedMemberPhone: true, showErrToast: false });
    return json;
};

export const KtCarShopListAPI = async () => {
    const url = `/keyTop/findKeyTopCarShopList`;
    const json = await remote.post(url, { isNeedMemberPhone: false, showErrToast: false });
    return json;
};



import React, { Component } from "react";
import Proptypes from "prop-types";
import PRIZE from "./prize";

import delay from "./delay";

import "./Carousel.less";

function getMoble() {
  var prefixArray = new Array(
    "130",
    "131",
    "132",
    "133",
    "135",
    "137",
    "138",
    "170",
    "187",
    "189",
    "185"
  );

  var i = parseInt(10 * Math.random());

  var prefix = prefixArray[i];

  for (var j = 0; j < 8; j++) {
    prefix = prefix + Math.floor(Math.random() * 10);
  }

  return prefix;
}

const genPhone = () => {
  return getMoble().replace(/\d{4}$/, "****");
};

const genPrize = () => {
  const ran = Math.random() * 100;

  if (ran < 0.06) {
    return PRIZE["2020051502"].name;
  }

  if (ran < 0.2) {
    return PRIZE["2020051503"].name;
  }

  if (ran < 1.96) {
    return PRIZE["2020051504"].name;
  }

  return PRIZE["2020051505"].name;
};

const generateList = (n) => {
  let arr = [];

  while (n > 0) {
    const phone = genPhone();
    const prize = genPrize();
    arr.push({
      phone,
      prize,
    });

    n -= 1;
  }
  return arr;
};

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: generateList(2),
    };

    this.interval = undefined;

    this.listRef = React.createRef();
  }

  static propTypes = {
    speed: Proptypes.number,
  };

  static defaultProps = {
    speed: 3,
  };

  clearTick = () => {
    if (this.interval !== undefined) {
      this.interval = undefined;
    }
  };

  componentDidMount() {
    this.interval = "start"; // 开启 tick

    const listEle = this.listRef.current;

    const listTransform = (n) => {
      listEle.style.transform = `translateY(-${n}%)`;
    };

    const handleNext = (go, to) => {
      return (complete) => translate(go, to)()(listTransform, complete);
    };

    const slide = async () => {
      await delay(this.props.speed * 1000);
      handleNext(
        0,
        100
      )(() => {
        this.setState(
          (prev) => ({
            ...prev,
            // 保留滚动位置下的item, 在前面插入一个新的
            list: [prev.list[1], ...generateList(1)],
          }),
          () => {
            if (this.interval === undefined) return; // 取消 loop

            listEle.style.transform = "translateY(0)"; // reset

            slide(); // 运行自身 loop
          }
        );
      });
    };

    // run
    slide();
  }

  componentWillUnmount() {
    this.clearTick();
  }

  render() {
    const { list } = this.state;

    return (
      <div className="carousel">
        <div className="list-wrap">
          <div className="list" ref={this.listRef}>
            {list.map(({ phone, prize }, i) => (
              <div className="item" key={i}>
                恭喜{phone} 获得{prize}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

function translate(current, to) {
  return function (duration = 1 * 1000) {
    return function (run, complete) {
      const dis = to - current;

      const timing = function (t) {
        return t;
      };

      animate(
        {
          timing: timing,
          draw: function (p) {
            const x = p * dis;
            run && run(current + x);
          },
          duration: duration,
        },
        complete
      );
    };
  };
}

function animate(options, complete) {
  const timing = options.timing;
  const draw = options.draw;
  const duration = options.duration;

  let start =
    window &&
    window.performance &&
    window.performance.now &&
    window.performance.now();

  requestAnimationFrame(function animate(time) {
    // timeFraction goes from 0 to 1
    if (!start) start = time;
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;

    // calculate the current animation state
    const progress = timing(timeFraction);

    draw(progress); // draw it

    if (timeFraction < 1) {
      requestAnimationFrame(animate);
    } else {
      complete && complete();
    }
  });
}

export default Carousel;

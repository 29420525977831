import React from "react";
import { connect } from "react-redux";
import { Icon, Checkbox, Tag, Toast } from "antd-mobile";
import { AppBar, ModalCustomize } from "../components"
import ListMore from "@/components/ListMore"
import Item from "./Item"
import { parkCurShop as parkCurShopStorage } from "@/util/storageManager";

import "./index.less";
import classNames from "@/util/classNames";
const prefix = "park-record";
const cx = classNames(prefix);

const ENV = process.env.NODE_ENV || "production";
const CheckboxItem = Checkbox.CheckboxItem;
class Record extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
        pageSize: 4,
        curPage: 1
      },
      list: [],
      isLoadingMore: false,
      isShowInvoice: false,  // 导航栏开发票文字显示
      invoiceModal: false,   // 发票弹框
      selectedVal: '',
      parksList: []
    };
  }

  fetchMore = async () => {
    const { curPage, pageSize } = this.state.page;
    const { shopNo } = parkCurShopStorage.getItem();
    const json = await this.props.handleHistoryList({ shopNo, pageSize, curPage: curPage + 1 });

    const { result: { data: list = [], page = {} } = {} } = json || {};

    this.setState((prev) => ({
      ...prev,
      page,
      isLoadingMore: false,
      list: [...prev.list, ...list],
    }));
  };

  componentDidMount = async () => {
    this.getParks();
    this.props.getKtCarShopList();
    const { shopNo } = parkCurShopStorage.getItem();

    // const { page } = this.state;
    const json = await this.props.handleHistoryList({ shopNo, ...this.state.page });
    const { result: { data: list = [], page = {} } = {} } = json || {};

    this.setState({ list, page });

  };

  getParks = async () => {
    const json = await this.props.getHistoryParks();
    const { code, data } = json;
    if (code === 0) {
      this.setState({
        parksList: data
      })
    }
  }

  handleInvoice = () => {
    const { parksList } = this.state;
    const isOneparks = Array.isArray(parksList) && parksList.length === 1;
    if (isOneparks) { // 有且只有一个杨浦店  直接跳转到开票的链接
      this.subInvoice(parksList[0].parkId)
    } else { // 含有多个停车场  显示选择停车场的弹框
      this.setState({
        invoiceModal: true
      })
    }
  }

  onChange = (checked, selectedVal) => {
    this.setState({
      selectedVal: checked ? selectedVal : ''
    })
  }

  subInvoice = (parkId) => {
    // const lotId = ENV === 'production' ? '210037541' : '2811';
    if (!parkId) {
      Toast.fail('请选择需要开发票的停车场');
      return
    }
    window.location.href = `https://cloud.keytop.cn/stcfront/PaymentRecord/Parking?lotId=${parkId}`;
    this.setState({ subInvoice: false })
  }

  render() {
    const { list, isLoadingMore, page, selectedVal, parksList } = this.state;
    const { ktCarShopListSelf, historyParksLoad, KtCarShopListLoad } = this.props;

    // parksList所有停车记录里面 自营+科拓的店铺  才显示发票按钮
    const filterItem = Array.isArray(parksList) && parksList.filter(i => ktCarShopListSelf.includes(i.shopNo));
    const isShowInvoice = Array.isArray(filterItem) && filterItem.length;

    return (
      <div className={cx("wrap")}>
        <AppBar title="缴费记录"
          rightSide={
            (historyParksLoad || KtCarShopListLoad) ? <Icon type="loading" /> :
              isShowInvoice ? <span className={cx("app-bar-side")} onClick={this.handleInvoice}>开发票</span> : null
          }
        />
        <div>
          <ul className={cx("list")}>
            {list.map((item, i) => (
              <Item key={i} data={item} index={i} />
            ))}
          </ul>
          <ListMore
            isLoading={this.props.loading}
            isLoadingMore={isLoadingMore}
            list={list}
            page={page}
            components={{
              loading: (
                <div className={cx("more")}>
                  <Icon type="loading" />
                </div>
              ),
              loadingMore: <div className={cx("more")}>加载更多...</div>,
              noMore: <div className={cx("more")}>以上为全部</div>,
              empty: <div className={cx("more")}>暂无记录</div>,
            }}
            handle={{
              setState: (state, next) => this.setState(state, next),
              state: { isLoadingMore: true },
              next: this.fetchMore,
            }}
          />
        </div>

        <ModalCustomize
          className={cx("modal")}
          visible={this.state.invoiceModal}
          transparent
          content={parksList.map(i => (
            <CheckboxItem
              disabled={!ktCarShopListSelf.includes(i.shopNo)}
              key={i.shopNo}
              onChange={(e) => this.onChange(e.target.checked, i.parkId)}
              checked={selectedVal === i.parkId}
            >
              {i.parkName}{!ktCarShopListSelf.includes(i.shopNo) && <Tag disabled>线下开票</Tag>}
            </CheckboxItem>
          ))}
          footer={[{
            text: '取消',
            onPress: (e) => {
              if (e) {
                e.preventDefault();
                this.setState({ invoiceModal: false, selectedVal: '' })
              }
            }
          }, {
            text: '确定',
            onPress: (e) => {
              if (e) {
                e.preventDefault();
                this.subInvoice(this.state.selectedVal)
              }
            }
          }]}
        />
      </div>
    );
  }
}


const key = "parkNew";
export default connect(
  (state) => {
    return {
      ktCarShopListSelf: state[key].ktCarShopListSelf,
      loading: state.loading.effects[key].historyList,
      historyParksLoad: state.loading.effects[key].getHistoryParks,
      KtCarShopListLoad: state.loading.effects[key].getKtCarShopList,
    };
  },
  (dispatch) => {
    return {
      handleHistoryList: dispatch[key].historyList,
      getHistoryParks: dispatch[key].getHistoryParks,
      getKtCarShopList: dispatch[key].getKtCarShopList,
    };
  }
)(Record);


import React from "react";
import Proptypes from "prop-types";
import "./index.less"


const ModalCustomize = ({ visible, title, content, footer, className, width = '80% ' }) => {
    return (
        visible && <div className={`new-modalCustomize ${className}`}>
            <div className="new-modalCustomize-wrap">
                <div className="new-modalCustomize-content" style={{ width }}>
                    {title && <p className="new-modalCustomize-title">{title}</p>}
                    <div className="new-modalCustomize-body">{content}</div>
                    <div className="new-modalCustomize-footer">
                        {footer.map(f => <a style={{width: footer.length > 1 ? '50%' : '100%'}} key={f.text} onClick={e => f.onPress(e)}>{f.text}</a>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalCustomize;

import React, { Component } from "react";
import Proptypes from "prop-types";

import "./Had.less";

import hadImg from "../images/had.png";

import Wrap from "./Wrap";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {};

  render() {
    return (
      <Wrap {...this.props}>
        <div className="had">
          <img src={hadImg} />
        </div>
      </Wrap>
    );
  }
}

export default Modal;

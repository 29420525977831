export let getScanList = (argus) => {
	return {
		type: 'WXSCANMSGLIST',		
		playload: {
			url: '/employee/entrance/list.do',
			type: 'post',
			param: {				
				...argus			
			}
		}
	}
}

export let updateWxScanListStatus = () => {
	return {
		type: 'UPDATE_WX_SCAN_LIST_STATUS'
	}
}
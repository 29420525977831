let initState = {

}
export default function (state = initState, action) {
	switch (action.type) {
		case 'GET_COUPON':
			return {
				...state,
				couponStatus: true,
				couponCode: action.playload.code,
				couponMessage: action.playload.message,
				couponData: action.playload.data
			}
			break;
		case 'GET_COUPON_FAIL':
			return {
				...state,
				status: true,
				code: action.playload.code,
				message: action.playload.message,
				data: action.playload.data
			}
			break;
		case 'UPDATE_COUPON_FAIL_STATUS':
			return {
				...state,
				status: false
			}
			break;
		case 'UPDATE_COUPON_STATUS':
			return {
				...state,
				couponStatus: false
			}
			break;
		case 'COMBILE_QR':
			return {
				...state,
				combileQrStatus: true,
				combileQrCode: action.playload.code,
				combileQrMessage: action.playload.message,
				combileQrData: action.playload.data
			}
			break;
		case 'COMBILE_QR_FAIL':
			return {
				...state,
				combileQrStatus: true,
				combileQrCode: action.playload.code,
				combileQrMessage: action.playload.message,
				combileQrData: action.playload.data
			}
			break;
		case 'UPDATE_COMBILE_QR_STATUS':
			return {
				...state,
				combileQrStatus: false
			}
			break;
		case 'GET_COUPON_BY_TYPE':
			return {
				...state,
				getCoupoByTypeStatus: true,
				getCoupoByTypeCode: action.playload.code,
				getCoupoByTypeMessage: action.playload.message,
				getCoupoByTypeData: action.playload.data,
				type:action.playload.diffType,
			}
			break;
		case 'GET_COUPON_BY_TYPE_FAIL':
			return {
				...state,
				getCoupoByTypeStatus: true,
				getCoupoByTypeCode: action.playload.code,
				getCoupoByTypeMessage: action.playload.message,
				getCoupoByTypeData: action.playload.data
			}
			break;
		case 'UPDATE_GET_COUPON_BY_TYPE_STATUS':
			return {
				...state,
				getCoupoByTypeStatus: false
			}
			break;
		default:
			return state;
	}
}
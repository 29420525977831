import React from "react";
import { Link } from "react-router-dom";
import BgBox from "../components/BgBox";
import qs from "qs";

import completeIcon from "../images/complete_icon.png";
import "./index.less";
import classNames from "@/util/classNames";
const prefix = "park-pay-result";
const cx = classNames(prefix);

export default class extends React.Component {
  render() {
    const { search } = this.props.location;

    const obj = qs.parse(search);

    const carNumber = obj.carNumber || obj["?carNumber"];

    return (
      <BgBox>
        <div className={cx("wrap")}>
          <p className={cx("title")}>缴费成功</p>
          <p className={cx("describe")}>请尽快离场，超时将重新计算。</p>
        </div>
      </BgBox>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'antd-mobile';
import { AppBar } from '../components';
import Item from './Item';
import RelocationIcon from '../images/relocation-icon.png';

import { parkCurShop } from '@/util/storageManager';

import './index.less';
import classNames from '@/util/classNames';
const prefix = 'park-shop';
const cx = classNames(prefix);

class Shop extends React.Component {
    componentDidMount = () => {
        const {
            parkNew: { list = [] },
            autoPosition,
        } = this.props;
        if (list.length === 0) {
            autoPosition();
        }
    };

    manualPosition = () => {
        if (this.props.manualPositionLoading) return;
        this.props.manualPosition();
    };

    onChangeShop = async (item) => {
        const {
            saveSelectedShop,
            location: { search },
            history,
        } = this.props;
        await saveSelectedShop(item);
        parkCurShop.setItem(item);

        const searchParams = search.split('=')[1] ? `/${search.split('=')[1]}` : '';
        history.replace(`/park${searchParams}`, { empty: false });
    };

    render() {
        const {
            parkNew,
            onChangeShop,
            manualPosition,
            autoPositionLoading,
            manualPositionLoading,
        } = this.props;
        const { list } = parkNew;

        return (
            <div className={cx('wrap')}>
                <AppBar title='门店列表' />
                {!autoPositionLoading ? (
                    <div className={cx('content')}>
                        {/* <div className={cx("px")}>
							<div className={cx("title")}>当前定位门店</div>
							<ul>
								<Item data={parkNew.selected.shopName ? parkNew.selected : list[0]}>
									<button className={cx("relocation")} onClick={this.manualPosition}>
										<div>重新定位</div>
										<img src={RelocationIcon} className={cx({ "spin": manualPositionLoading })} />
									</button>
								</Item>
							</ul>
						</div>
						<div style={{ height: "2em" }} /> */}
                        <ul className={cx('list')}>
                            {list.map((item, i) => (
                                <Item key={i} data={item} onClick={() => this.onChangeShop(item)} />
                            ))}
                        </ul>
                    </div>
                ) : (
                    <div className={cx('loading-wrap')}>
                        <Icon type='loading' size='md' color='red' />
                        <p>加载中...</p>
                    </div>
                )}
            </div>
        );
    }
}

const key = 'parkNew';
export default connect(
    (state) => {
        return {
            parkNew: state.parkNew,
            autoPositionLoading: state.loading.effects[key].autoPosition,
            manualPositionLoading: state.loading.effects[key].manualPosition,
        };
    },
    (dispatch, ownProps) => {
        return {
            autoPosition: dispatch[key].autoPosition,
            manualPosition: dispatch[key].manualPosition,
            saveSelectedShop: dispatch[key].saveSelectedShop,
        };
    }
)(Shop);

let initState = {
	
}
export default function(state=initState, action) {

	switch(action.type) {

		case 'QUERY_INFO':
		   //用户信息查询成功后，存储用户手机
		   let data = action.playload.data;
		   window.localStorage.setItem('info', JSON.stringify(data));
			return {
				...state,
				loginStatus: true,
				code: action.playload.code,
				message: action.playload.message,
				data:action.playload.data
			}
			break;
		case 'QUERY_INFO_FAIL':
			return {
				...state,
				loginStatus: false,
				code: action.playload.code,
				message: action.playload.message
			}	
			break;
		case 'UPDATE_LOGIN_STATUS':
			return {
				...state,
				loginStatus: false
			}
			break;		
		case 'GET_INDEX_IMG':
			 return {
				 ...state,
				 indexImgStatus: true,
				 indexImgCode: action.playload.code,
				 indexImgMessage: action.playload.message,
				 indexImgData:action.playload.data
			 }
			 break;
		 case 'GET_INDEX_IMG_FAIL':
			 return {
				 ...state,
				 indexImgStatus: false,
				 indexImgCode: action.playload.code,
				 indexImgMessage: action.playload.message
			 }	
			 break;
		 case 'UPDATE_GET_INDEX_IMG_STATUS':
			 return {
				 ...state,
				 indexImgStatus: false
			 }
			 break;		
		default:
			return state;
	}
}
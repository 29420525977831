import React, { Component } from "react";
import { Route } from "react-router-dom";
import { info as infoStorage } from "../../util/storageManager";

import classNames from "../../util/classNames";

import "./index.less";

const prefix = "components-auth";

const cx = classNames(prefix);

import { login as loginAPI, queryInfo as queryInfoAPI } from "./api";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    if (infoStorage.getItem()) {
      this.setState({ isLoading: false });
      return; //  break
    }

    const { location: { search } = {} } = this.props;
    const params = new URLSearchParams(search);

    const mobile = params.get("mobile");

    if (mobile) {
      infoStorage.setItem({ mobile });
      this.setState({ isLoading: false });
      return; //  break
    }

    // loading...
    this.setState({ isLoading: true });

    try {
      await loginAPI();

      const json = await queryInfoAPI();

      const { result: { data } = {} } = json || {};

      if (!data) {
        throw new Error("get info faild");
      }

      infoStorage.setItem(data);

      this.setState({ isLoading: false });
    } catch (error) {
      const { history, location: { pathname, search } = {} } = this.props;

      const redirectUrl = `${pathname}${search}`;

      history.push(`/?redirectUrl=${redirectUrl}`);
    }
  };

  render() {
    const { component } = this.props;

    const { isLoading } = this.state;

    const loading = <div className={cx("wrap")}>正在登录中...</div>;

    const renderComponent = isLoading ? loading : component;

    return renderComponent;
  }
}

/**
 * component是route配置中声明的组件,一般指页面组件
 */
export default ({ component, ...rest }) => {
  const Component = component;
  /**
   * props 必须传入 Auth 和 component 组件中
   * 这样才可以使用 route相关的props,比如history, location...
   */
  return (
    <Route
      {...rest}
      component={(props) => (
        <Auth {...props} component={<Component {...props} />} />
      )}
    />
  );
};

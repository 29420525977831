
export let getUserInfo = () =>{
	return {
		type: 'GET_USER_INFO',		
		playload: {
			url: '/web/user/info',
			type: 'get'
		}
	}
}

export let updateGetUserInfoStatus = () =>{
	return {
		type:'UPDATE_GET_USER_INFO_STATUS'
	}
}

export let getEnums = () =>{
	return {
		type: 'GET_ENUMS',		
		playload: {
			url: '/web/user/enum',
			type: 'get'
		}
	}
}


export let updateGetEnumsStatus = () =>{
	return {
		type:'UPDATE_GET_ENUMS_STATUS'
	}
}


export let getTags = () =>{
	return {
		type: 'GET_TAGS',		
		playload: {
			url: '/web/labels',
			type: 'get'
		}
	}
}

export let updateGetTagsStatus = () =>{
	return {
		type:'UPDATE_GET_TAGS_STATUS'
	}
}

export let submitData = (argus) =>{
	return {
		type: 'SUBMIT_DATA',		
		playload: {
			url: '/web/update/user',
			type: 'post',
			param:{
				...argus
			}
		}
	}
}

export let updateSubmitDataStatus = () =>{
	return {
		type:'UPDATE_SUBMIT_DATA_STATUS'
	}
}


export let getAllAddress = (argus) =>{
	return {
		type: 'GET_ALL_ADDRESS',		
		playload: {
			url: '/admin/getAllAddress.do',
			type: 'get',
		}
	}
}

export let updateGetAllAddressStatus = () =>{
	return {
		type:'UPDATE_GET_ALL_ADDRESS_STATUS'
	}
}

export let getStreetAddress = (argus) =>{
	return {
		type: 'GET_STREET_ADDRESS',		
		playload: {
			url: '/admin/codeStreet.do',
			type: 'get',
			param:{
				...argus
			}
		}
	}
}

export let updateGetStreetAddressStatus = () =>{
	return {
		type:'UPDATE_GET_STREET_ADDRESS_STATUS'
	}
}
import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "antd-mobile";

import classNames from "@/util/classNames";
import { diffTime } from "@/util";
import "./Item.less";
const prefix = "park-record-item";
const cx = classNames(prefix);

export default ({
  data: {
  id,
  orderNo,
  amount,
  shopName,
  carNumber,
  parkName,
  startTime,
  startTimeStr,
  endTime,
  endTimeStr,
  } = {},
}) => {
  const parkingTime = diffTime(endTime, startTime);

  return (
    <li className={cx("li")}>
      <p>
        <span>订单号:<i>{orderNo}</i></span>
        <span>{shopName}</span>
      </p>
      <div className={cx("detail")}>
        <Link to={`/park/record/detail/${id}`}>
          <p>车牌号：{carNumber}</p>
          <p>入场时间:&nbsp;&nbsp;{startTimeStr}</p>
          <p>
            <span>离场时间:&nbsp;&nbsp;{endTimeStr}</span>
            <span>{amount}<Icon type="right" size="xs" color="#979797" /></span>
          </p>
          <p>停车时长:&nbsp;&nbsp;{parkingTime}</p>
        </Link>
      </div>
    </li>
  );
};

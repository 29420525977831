export let wxLoginUser = (argus) => {
	return {
		type: 'WX_LOGIN_USER',		
		playload: {
			url: '/public/wx/user/base',
			type: 'get',
			param: {				
				...argus			
			}
		}
	}
}

export let updateWxLoginStatus = () => {
	return {
		type: 'UPDATE_WX_LOGIN_USER_STATUS'
	}
}


export let loginUser = (argus) => {
	return {
		type: 'LOGIN_USER',		
		playload: {
			url: '/public/web/quickLogin.do',
			type: 'post',
			param: {				
				...argus			
			}
		}
	}
}

export let updateLoginUserStatus = () => {
	return {
		type: 'UPDATE_LOGIN_USER_STATUS'
	}
}

export let getCode=(argus)=>{
	return {
		type:"GET_CODE",
		playload: {
			url: '/public/web/getVerifyCode.do',
			type: 'post',
			param: {
				...argus
			}
		}
	}
}

export let updateGetCodeStatus = () => {
	return {
		type: 'UPDATE_GET_CODE_STATUS'
	}
}

export let wechartLogin=(argus)=>{
    return {
        type:'WECHART_LOGIN',
        playload:{
            url:'/public/wx/login',
            type:'post',
            param:{
                ...argus
            }
        }
    }
}

export let updateWechartLoginStatus=()=>{
    return {
        type:'UPDATE_WECHART_LOGIN_STATUS'
    }
}
let initState = {
	
}
export default function(state=initState, action) {
	switch(action.type) {
		case 'WXSCANMSGLIST':
			return {
				...state,
				tableStatus: true,
				tableCode: action.playload.code,
				tableMessage: action.playload.message,
				tableData: action.playload.data,
				tablePage: action.playload.page
			}
			break;
		case 'WXSCANMSGLIST_FAIL':
			return {
				...state,
				tableStatus: true,
				tableCode: action.playload.code,
				tableMessage: action.playload.message
			}	
			break;
		case 'UPDATE_WX_SCAN_LIST_STATUS':
			return {
				...state,
				tableStatus: false
			}
			break;	    		
		default:
			return state;
	}
}
import React, { Component } from "react";
import Proptypes from "prop-types";

import { Toast } from "antd-mobile";

import "./login.less";

import Wrap from "./Wrap";
import request, { BASE } from "../request";
import { prizeMayAuth } from "../storageManager";

import GetVerifyCodeBtn from "./GetVerifyCodeBtn";

const ACTIVITY_NO = "20200515";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgToken: "",
      graphName: new Date().getTime(),
      agreement: false,
      mobile: "",
      imgCode: "", // 请输入图形验证码
      verifyCode: "", // 请输入手机验证码
    };
  }

  static propTypes = {
    visible: Proptypes.bool,
    handleModal: Proptypes.func,
    getLottery: Proptypes.func,
  };

  mobileOnChange = (e) => {
    const { value: mobile } = e.target;
    this.setState({ mobile });

    if (mobile.length === 11) {
      this.setState({
        imgToken: mobile,
      });
    }
  };

  agreementOnChange = (e) => {
    this.setState({
      agreement: e.target.checked,
    });
  };

  freshGraph = () => {
    this.setState({
      graphName: new Date().getTime(),
    });
  };

  makeSubmit = async () => {
    const { mobile, verifyCode, agreement } = this.state;
    if (mobile.trim() === "") {
      Toast.fail("请输入手机号");
      return;
    }

    if (verifyCode.trim() === "") {
      Toast.fail("请输入手机验证码");
      return;
    }

    if (!agreement) {
      Toast.fail("需同意《百安居会员协议》");
      return;
    }

    const url = "/wx-web/public/biz/activity/login";
    const options = {
      method: "POST",
      body: JSON.stringify({
        data: {
          mobile: mobile.trim(),
          verifyCode: verifyCode.trim(),
          isAgree: 1,
          activityNo: ACTIVITY_NO,
        },
      }),
    };

    Toast.loading("请求正在发送中...", 0);

    try {
      const json = await request(url, options);
      const { result: { data } = {}, msg = "登录/注册失败, 请稍后再试" } = json;
      // token是段字符串 且 不为空
      if (typeof data === "string" && data) {
        prizeMayAuth.setItem({
          token: data,
          phone: mobile,
        });

        Toast.hide();

        // 关闭弹窗 开始抽奖
        this.props.handleModal(0);
        this.props.getLottery();
      } else {
        throw new Error(msg);
      }
    } catch (error) {
      Toast.fail(error && error.message);
    }
  };

  inputBlurScroll() {
    document.body.scrollTop = 0;
  }

  render() {
    const {
      imgToken,
      graphName,
      mobile,
      imgCode,
      verifyCode,
      agreement,
    } = this.state;

    return (
      <Wrap {...this.props}>
        <div className="login">
          <div className="prize-title">登录/注册百安居会员</div>
          <input
            type="text"
            className="phone-input"
            placeholder="请输入手机号"
            value={mobile}
            onBlur={this.inputBlurScroll}
            onChange={this.mobileOnChange}
          />
          {imgToken && (
            <div className="graph-verification-wrap">
              <div className="graph-verification-input-wrap">
                <input
                  type="text"
                  className="graph-verification-input"
                  placeholder="请输入图形验证码"
                  value={imgCode}
                  onBlur={this.inputBlurScroll}
                  onChange={(e) => this.setState({ imgCode: e.target.value })}
                />
              </div>

              <div className="graph">
                <img
                  onClick={this.freshGraph}
                  src={`${BASE}/wx-web/public/web/getValidateCode.do?imgToken=${imgToken}&name=${graphName}`}
                />
              </div>
            </div>
          )}

          <div className="phone-verification-wrap">
            <div className="phone-verification-input-wrap">
              <input
                className="phone-verification-input"
                placeholder="请输入手机验证码"
                value={verifyCode}
                onBlur={this.inputBlurScroll}
                onChange={(e) => this.setState({ verifyCode: e.target.value })}
              />
            </div>
            <GetVerifyCodeBtn mobile={mobile} imgCode={imgCode} />
          </div>
          <div className="agreement">
            <input
              type="checkbox"
              checked={agreement}
              onChange={this.agreementOnChange}
            />
            <div className="agreement-label">
              我已阅读并同意《百安居会员协议》
            </div>
          </div>
          <div className="submit-wrap">
            <input
              type="submit"
              className="submit"
              value="确认"
              onClick={this.makeSubmit}
            />
          </div>
        </div>
      </Wrap>
    );
  }
}

export default Modal;

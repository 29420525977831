import React from "react";
import Proptypes from "prop-types";
import "./index.less"


const ModalCustomize = ({ visible, title, content, footer, className, width = '65% ' }) => {
    return (
        visible && <div className={`modalCustomize ${className}`}>
            <div className="modalCustomize-wrap">
                <div className="modalCustomize-content" style={{ width }}>
                    {title && <p className="modalCustomize-title">{title}</p>}
                    <div className="modalCustomize-body">{content}</div>
                    <div className="modalCustomize-footer">
                        {footer.map(f => <a key={f.text} onClick={e => f.onPress(e)}>{f.text}</a>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalCustomize;

import React from "react";
import { connect } from "react-redux";
import { Toast, Modal, Icon } from "antd-mobile";
import { Head, AppBar, BgBox, CarInputKeyboard, NewModalCustomize } from "../components";

const prefix = "park-add";
const cx = classNames(prefix);
import "./index.less";
import classNames from "@/util/classNames";

const INPUT_DESCRIBE = "请绑定真实有效的车牌号";
const TITLE = "变更绑定车牌";

const alert = Modal.alert;

class Edit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			carNumber: "",
			submitting: false,
			modalDel: false,
			modalChange: false,
		};
	}

	onClick = async (e, type) => {
		if (e) e.preventDefault();
		const { id } = this.props.match.params;
		if (type === 'cancel') {
			this.setState({ modalChange: false })
			return
		}
		const { carNumber } = this.state;
		try {
			const { id } = this.props.match.params;
			await this.props.handleCarUpdate({
				id,
				carNumber: carNumber.trim(),
			});
			Toast.success("变更车牌成功", 1, () => {
				this.props.history.push("/park");
			})
		} catch (error) {
			Toast.fail(error.message);
		}
	};

	onClickChange = (e, type) => {
		
		const { carNumber } = this.state;
		try {
			if (!carNumber.trim()) {
				throw new Error("请填写车牌号");
			}
			if (carNumber.trim().length < 7) {
				throw new Error("请填写正确的车牌号");
			}
			this.setState({ modalChange: true });
			// const { id } = this.props.match.params;
			// await this.props.handleCarUpdate({
			// 	id,
			// 	carNumber: carNumber.trim(),
			// });
			// Toast.success("变更车牌成功", 1, () => {
			// 	this.props.history.push("/park");
			// })
		} catch (error) {
			Toast.fail(error.message);
		}
	}

	onChange = (carNumber) => {
		this.setState({ carNumber });
	};

	handleDelPre = () => {
		const { delLoading } = this.props
		if (!!delLoading) return;
		this.setState({
			modalDel: true
		})
	}

	handleDel = async (e, type) => {
		if (e) e.preventDefault();
		const { id } = this.props.match.params;
		if (type === 'cancel') {
			this.setState({ modalDel: false })
			return
		}
		try {
			this.setState({ modalDel: false })
			await this.props.handleCarDelete(id);
			Toast.success("删除成功，您还没绑定车辆, 请先绑定", 1, () => {
				this.props.history.replace("/park/add", { empty: true });
			});
		} catch (error) {

		}
	}

	componentDidMount = async () => {
		const { id } = this.props.match.params;
		try {
			const json = await this.props.handleCarDetailById(id);
			const { result: { data: { carNumber } } = {} } = json || {};
			this.setState({ carNumber: '', originalCarNum: carNumber });
			Toast.hide();
		} catch (error) {
			console.log(error, "error")
		}
	};

	render() {
		const { detailLoading, updateLoading, delLoading } = this.props
		const { originalCarNum, carNumber, modalDel, modalChange } = this.state;

		return (
			<div className={cx("wrap")}>
				<AppBar title={TITLE} />
				<Head />
				{!detailLoading ?
					<BgBox className={cx("box")}>
						<div className={cx("original-box")}>
							<p>当前绑定车牌</p>
							<p>
								<span><div className={cx("car-icon")}></div>{originalCarNum}</span>
								<span onClick={this.handleDelPre} className={cx({ "original-box-del": delLoading })}>解绑</span>
							</p>
						</div>
						<CarInputKeyboard
							btnText="绑定"
							title="请输入变更后的车牌"
							btnProps={{
								disabled: updateLoading || delLoading,
								onClick: this.onClickChange,
							}}
							onChange={this.onChange}
							inputProps={{ value: carNumber, placeholder: INPUT_DESCRIBE }}
						/>
					</BgBox> : <div className={cx("loading-wrap")}>
						<Icon type="loading" size="md" />
						<p>加载中...</p>
					</div>
				}
				<NewModalCustomize
					visible={modalDel}
					title="是否解绑车牌"
					content={
						<p style={{marginTop: '12px', marginBottom: '20px', color: '#4B596B', fontSize: '14px' }}>
							您确定解绑车牌{originalCarNum}
						</p>
					}
					footer={[
						{ text: '取消', onPress: (e) => this.handleDel(e, 'cancel') },
						{ text: '解绑', onPress: (e) => this.handleDel(e, 'sure') }
					]}
				/>
				<NewModalCustomize
					visible={modalChange}
					title="变更车牌号"
					content={
						<p style={{marginTop: '12px', marginBottom: '20px', color: '#4B596B', fontSize: '14px', lineHeight: 'normal' }}>
							您即将绑定车牌{carNumber}，<br></br>
							原车牌{originalCarNum}将被解绑
						</p>
					}
					footer={[
						{ text: '取消', onPress: (e) => this.onClick(e, 'cancel') },
						{ text: '绑定', onPress: (e) => this.onClick(e, 'sure') }
					]}
				/>
			</div>
		);
	}
}

const key = "parkNew";
export default connect(
	(state) => {
		return {
			updateLoading: state.loading.effects[key].carUpdate,
			detailLoading: state.loading.effects[key].carDetailById,
			delLoading: state.loading.effects[key].carDel,
		};
	},
	(dispatch) => {
		return {
			handleCarUpdate: dispatch[key].carUpdate,
			handleCarDelete: dispatch[key].carDel,
			handleCarDetailById: dispatch[key].carDetailById,
		};
	}
)(Edit);

export let getCoupon = (argus) => {
	return {
		type: 'GET_COUPON',		
		playload: {
			url: '/web/getCouponFromActivityRecord.do',
            type: 'get',
		}
	}
}

export let changeCouponFailStatus = ()=>{
	return {
		type:'UPDATE_COUPON_FAIL_STATUS'
	}
}

export let changeCouponStatus = ()=>{
	return {
		type:'UPDATE_COUPON_STATUS'
	}
}

export let combileQr = (argus) => {
	return {
		type: 'COMBILE_QR',		
		playload: {
			url: '/web/mergeQRCode.do',
			type: 'post',
			param:argus
		}
	}
}

export let updateCombileQrStatus = ()=>{
	return {
		type:'UPDATE_COMBILE_QR_STATUS'
	}
}


export let getCouponByType = (argus) => {
	return {
		type: 'GET_COUPON_BY_TYPE',		
		playload: {
			url: '/web/getCouponFromActivityRecord.do',
			type: 'get',
			param:{...argus},
			diffType:{...argus}
		}
	}
}

export let updateGetCouponByTypeStatus = ()=>{
	return {
		type:'UPDATE_GET_COUPON_BY_TYPE_STATUS'
	}
}
import React, { Component } from "react";

import { Toast } from "antd-mobile";

import { prizeMayAuth } from "./storageManager";

import delay from "./delay";
import { getPos } from "./prize";

import "./index.less";

import LoginModal from "./modals/Login";
import WinModal from "./modals/Win";
import RuleModal from "./modals/Rule";
import UseUpModal from "./modals/UseUp";
import HadModal from "./modals/Had";
import NotifyModal from "./modals/Notify";

import Carousel from "./Carousel";

import imgBg from "./images/bg.png";
import imgLogo from "./images/logo.png";
import imgTitle from "./images/title.png";
import imgMain from "./images/main.png";
import imgGo from "./images/go.png";

import request from "./request";

import { lotteryInfoApi, lotteryFinalApi } from "./lottery";

import imgInfo, { calcImg } from "./imgInfo";
import { ranRange } from "./ran";

const getHeigthR = (height) => {
  const { title, titleBottom, main } = imgInfo;
  const tH = title[1];
  const mH = main[1];

  const total = tH - titleBottom + mH;
  const r = height / total;

  return r;
};

class PrizeMay extends Component {
  constructor(props) {
    super(props);

    document.title = "百安居五月幸运大抽奖";

    const oMeta = document.createElement("meta");
    oMeta.name = "description";
    oMeta.content = "赶快加入抽奖，戴森空气净化器等你来拿！";
    document.getElementsByTagName("head")[0].appendChild(oMeta);

    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?32c01b31e6c20ae5d89fe6ddf00887f5";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();

    this.contentRef = React.createRef();

    this.state = {
      /**
       * 活动接口 返回的 状态
       * 1有效
       * 2过期
       * 0没开始
       */
      status: null,

      modal: 0,

      /**
       * IDLE
       * ING
       * STOP
       */
      goStatus: "IDLE", // 转盘状态
      goTransform: 0,

      title: [], // title里的图片
      titleHeight: 0,
      main: [],
      go: [],

      lotteryNo: "", //抽中 奖品的 编号
      couponNo: "",
    };

    Toast.loading("加载中...", 0);
  }

  imgTitleOnLoad = (e) => {
    const { offsetWidth, offsetHeight } = this.contentRef.current;
    const { title, titleBottom } = imgInfo;

    const titleHeight = offsetHeight * getHeigthR(title[1] - titleBottom);

    const r = titleBottom / title[1];

    const mw = offsetWidth;
    const mh = (r + 1) * titleHeight;

    this.setState({
      titleHeight,
      title: calcImg([mw, mh], title),
    });
  };

  imgMainOnLoad = () => {
    const { offsetWidth, offsetHeight } = this.contentRef.current;
    const { main, go } = imgInfo;

    const mw = offsetWidth;
    const mh = offsetHeight * getHeigthR([main[1]]);

    const finaMainSize = calcImg([mw, mh], main);

    const r = main[0] / go[0];
    const goW = finaMainSize[0] / r;

    const goR = go[0] / go[1];

    const goH = goW / goR;

    const finaGoSize = [goW, goH];
    this.setState({
      main: finaMainSize,
      go: finaGoSize,
    });
  };

  resetGo = async () => {
    this.setState({
      goTransform: 0,
    });

    await delay(200);

    this.setState({
      goStatus: "IDLE",
    });
  };

  getLottery = async () => {
    const auth = prizeMayAuth.getItem();

    if (auth) {
      try {
        Toast.loading("准备抽奖中...", 0);
        const info = await lotteryInfoApi();
        const { data: infoData, msg: infoMsg = "" } = info || {};
        // real_count 实际抽奖次数
        // total_count
        const { total_count, lottery_count = -1 } = infoData || {};

        if (total_count <= 0) {
          const error = new Error(infoMsg);
          error.modal = "useup";
          throw error;
        }

        if (lottery_count > 0) {
          const error = new Error(infoMsg);
          error.modal = "had";
          throw error;
        }

        const json = await lotteryFinalApi();
        const { data, msg = "" } = json || {};
        const { lotteryNo, couponNo } = data || {};

        if (!lotteryNo) {
          throw new Error(msg);
        }

        Toast.hide();
        this.handleGo({ lotteryNo, couponNo });
      } catch (error) {
        Toast.hide();
        const { modal } = error || {};
        if (modal) {
          this.handleModal(modal);
          this.resetGo(); // 重置go
          return; // break
        }

        Toast.info(error.message);
      }
    } else {
      this.handleModal("login");
    }
  };

  handleGo = async ({ lotteryNo, couponNo }) => {
    const pos = getPos(lotteryNo);

    if (pos === 0) {
      this.handleModal("had");
      this.resetGo(); // 重置go
      return;
    }

    const prize = (360 / 6) * pos;

    const goTransform = ranRange(3, 6) * 360 + prize;

    if (this.state.goStatus !== "IDLE") return;

    this.setState({
      goTransform,
      goStatus: "ING",
    });

    await delay(1000 * 4);

    this.setState({
      goTransform,
      goStatus: "STOP",
    });

    /**
     * 中奖
     */
    this.setState({ lotteryNo, couponNo });

    this.handleModal("win");
    this.resetGo(); // 重置go
  };

  handleModal = (modal) => {
    this.setState({
      modal,
    });
  };

  render() {
    const {
      status,
      goTransform,
      goStatus,
      title,
      titleHeight,
      main,
      go,
      couponNo,
      lotteryNo,
    } = this.state;

    const goYOrigin = (0.5 + (1 - imgInfo.go[0] / imgInfo.go[1]) / 2) * 100;

    const imgsIsComplete =
      titleHeight > 0 &&
      title.length === 2 &&
      main.length === 2 &&
      go.length === 2;

    const imgGoClasses = goStatus === "STOP" ? "img-go stop" : "img-go";
    return (
      <div className="prize-may" style={{ backgroundImage: `url(${imgBg})` }}>
        {/* 图片位置调整中 遮挡一下  */}
        {!imgsIsComplete && <div className="prize-may-loading">加载中...</div>}
        <div className="head">
          <div className="logo">
            <img src={imgLogo} alt="logo" />
          </div>
          <div className="rule-btn" onClick={() => this.handleModal("rule")}>
            活动细则
          </div>
        </div>
        <div className="content" ref={this.contentRef}>
          <div className="prize-title" style={{ height: `${titleHeight}px` }}>
            <div className="title-img-wrap">
              <img
                width={title[0]}
                height={title[1]}
                src={imgTitle}
                alt="title"
                onLoad={this.imgTitleOnLoad}
              />
            </div>
          </div>
          <div className="main">
            <img
              width={main[0]}
              height={main[1]}
              src={imgMain}
              alt="main"
              onLoad={this.imgMainOnLoad}
            />
            <div className="img-go-wrap">
              <img
                style={{
                  transformOrigin: `center ${goYOrigin}%`,
                  transform: `rotate(${goTransform}deg)`,
                }}
                className={imgGoClasses}
                width={go[0]}
                height={go[1]}
                src={imgGo}
                alt="go"
                onClick={this.getLottery}
              />
            </div>
          </div>
        </div>
        <div
          className="carousel-info"
          // 用 visibility 不影响大小计算, 处理方便
          style={{ visibility: status == 1 ? "visible" : "hidden" }}
        >
          <Carousel />
        </div>

        <RuleModal
          visible={this.state.modal === "rule"}
          handleModal={this.handleModal}
        />
        <LoginModal
          visible={this.state.modal === "login"}
          handleModal={this.handleModal}
          getLottery={this.getLottery}
        />

        <WinModal
          hideBack={true}
          couponNo={couponNo}
          lotteryNo={lotteryNo}
          visible={this.state.modal === "win"}
          handleModal={this.handleModal}
          getLottery={this.getLottery}
        />

        <UseUpModal
          visible={this.state.modal === "useup"}
          handleModal={this.handleModal}
          hideBack={true}
        />

        <HadModal
          visible={this.state.modal === "had"}
          handleModal={this.handleModal}
        />

        <NotifyModal
          visible={this.state.modal === "notify"}
          handleModal={this.handleModal}
        />
      </div>
    );
  }

  async componentDidMount() {
    const defaultMessage = "加载有误, 请稍后再试";

    try {
      const json = await request("/wx-web/public/thw/lottery/status");
      const { data, msg = defaultMessage } = json;

      this.setState({
        status: data,
      });

      if (data == 0) {
        // 没开始
        Toast.info(msg);
      }

      if (data == 2) {
        // 过期
        Toast.info(msg, 0);
      }

      if (data == 1) {
        //有效
        Toast.hide();
      }
    } catch (error) {
      Toast.fail(defaultMessage);
    }

    window.addEventListener("resize", this.reportWindowSize);
  }

  reportWindowSize = () => {
    this.imgTitleOnLoad();
    this.imgMainOnLoad();
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.reportWindowSize);
  }
}

export default PrizeMay;

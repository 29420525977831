import React from "react";
import Proptypes from "prop-types";
import { Icon } from "antd-mobile";

import classNames from "@/util/classNames";
import "./index.less";
const prefix = "park-car-input";

const cx = classNames(prefix);

const CarInput = ({
  	describe,
	inputProps,
	btnProps,
	btnText,
	submitting,
	inputRef,
}) => {
	return (
		<div className={prefix}>
			<div className={cx("content")}>
				<div className={cx("up")}>
					<div className={cx("input-wrap")}>
						<input ref={inputRef} className={cx("input")} {...inputProps} />
					</div>
					<button className={cx("btn")} {...btnProps}>
						<div className={cx("btn-text")}>
							{submitting ? <Icon type="loading" /> : btnText}
						</div>
					</button>
				</div>
				<div className={cx("describe", { show: inputProps.value })}>
					{describe}
				</div>
			</div>
		</div>
	);
};

CarInput.defaultProps = {
	inputProps: {},
	btnProps: {},
	describe: "",
};

CarInput.propTypes = {
	btnText: Proptypes.string.isRequired,
	inputProps: Proptypes.object,
	btnProps: Proptypes.object,
	describe: Proptypes.string,
};

export default CarInput;

import React, { Component } from "react";
import Proptypes from "prop-types";

import "./Wrap.less";

import img from "../images/modal.png";

import { calcImg } from "../imgInfo";

const NATURAL_SIZE = [747, 1130];

class Wrap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lazy: false,
      size: [],
    };
  }
  static propTypes = {
    visible: Proptypes.bool,
    handleModal: Proptypes.func,
    hideBack: Proptypes.bool,
    handleBack: Proptypes.func,
  };

  static defaultProps = {
    visible: false,
    handleModal: () => {},
    hideBack: false,
    handleBack: null, // 默认设置为null 后面已经设置了默认方法
    img,
  };

  componentDidUpdate() {
    const { lazy } = this.state;

    if (this.props.visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }

    if (lazy === false && this.props.visible === true) {
      // 只执行一次
      this.setState({
        lazy: true,
      });
    }
  }

  imgOnLoad = () => {
    const { clientWidth, clientHeight } = document.body;
    const size = calcImg([clientWidth, clientHeight - 100], NATURAL_SIZE);
    this.setState({ size });
  };

  render() {
    const { lazy, size } = this.state;
    if (lazy === false) {
      return null;
    }

    const modalClasses = this.props.visible ? "modal visible" : "modal";

    const imgsIsComplete = size.length === 2;

    return (
      <div className={modalClasses}>
        <div className="mask" />

        {!imgsIsComplete && <div className="modal-loading">请稍后...</div>}

        <div style={{ visibility: imgsIsComplete ? "visible" : "hidden" }}>
          <div className="modal-content-wrap">
            <img
              width={size[0]}
              height={size[1]}
              src={this.props.img}
              onLoad={this.imgOnLoad}
            />
            <div className="modal-content">{this.props.children}</div>
          </div>

          {!this.props.hideBack && imgsIsComplete && (
            <div className="back-wrap">
              <div
                className="back"
                onClick={() => {
                  this.props.handleBack
                    ? this.props.handleBack()
                    : this.props.handleModal(0);
                }}
              >
                返回
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Wrap;

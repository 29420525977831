export let wxGetOpenid = (argus) => {
	return {
		type: 'GETOPENID',		
		playload: {
			url: '/employee/entrance/getOpenid.do',
			type: 'get',
			param: {				
				...argus			
			}
		}
	}
}

export let updateGetOpenidStatus = () => {
	return {
		type: 'UPDATE_GETOPENID'
	}
}



import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "antd-mobile";
import Proptypes from "prop-types";

import classNames from "@/util/classNames";
import "./index.less";
const prefix = "park-app-bar";
const cx = classNames(prefix);

const BackWrap = ({ children, ...rest }) => {
	const { to: toLink } = rest;

	if (toLink === null) {
		return null;
	}

	if (toLink) {
		const { onClick, ...linkProps } = rest; // 过滤 onClick
		return <Link {...linkProps}>{children}</Link>;
	}
	const { to, ...divProps } = rest;

	return <div {...divProps}>{children}</div>;
};

const AppBar = ({ theme, position, title, rightSide, link }) => {
	return (
		<div
			className={cx("wrap", {
				"non-static": ["fixed", "absolute"].indexOf(position) !== -1,
			})}
		>
			<div
				className={cx("content")}
				style={{ position, backgroundColor: theme }}
			>
				<div className={cx("left-side")}>
					<BackWrap
						className={cx("back")}
						to={link}
						onClick={() => {
							history.back();
						}}
					>
						<Icon type="left" color="#F28101" />
					</BackWrap>
				</div>

				{title && <div className={cx("title")}>{title}</div>}

				{rightSide}
			</div>
		</div>
	);
};

AppBar.defaultProps = {
	theme: "#fff",
	position: "fixed",
};

AppBar.propTypes = {
	theme: Proptypes.string,
	link: Proptypes.string,
};

export default AppBar;

import React from "react";
import Proptypes from "prop-types";

import { Icon } from "antd-mobile";

import Keyboard from "./Keyboard";

import "./index.less";

import classNames from "../../../../util/classNames";

const prefix = "park-car-input-panel";

const cx = classNames(prefix);

const SwitchInputBase = ({ simulate, inputProps }, ref) => {
  const { value, placeholder = "" } = inputProps;

  if (simulate) {
    return (
      <div className={cx("input", "simulate", { "input-empty": value === "" })}>
        {value === "" ? placeholder : value}
      </div>
    );
  }

  return <input ref={ref} className={cx("input")} {...inputProps} />;
};

const SwitchInput = React.forwardRef(SwitchInputBase);

export default class CarInputPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simulateInput: true,
    };

    this.inputRef = React.createRef();
  }
  static propTypes = {
    /**
     * 不要与 inputProps里的 onChange 混淆
     */
    onChange: Proptypes.func.isRequired,

    // 提交的结果 可以是 inputProps 里面的 value
    inputProps: Proptypes.object,

    btnProps: Proptypes.object,
    btnText: Proptypes.string.isRequired,
  };

  static defaultProps = {
    inputProps: {
      onChange: () => {},
      value: "",
    },
    btnProps: {},
  };

  componentDidUpdate = (_, prevState) => {
    const { simulateInput } = this.state;

    const { simulateInput: prevSimulateInput } = prevState;

    if (simulateInput !== prevSimulateInput) {
      if (simulateInput === false) {
        this.inputRef.current.focus();
      }
    }
  };

  toggleSimulateInput = () => {
    this.setState((prev) => ({
      ...prev,
      simulateInput: !prev.simulateInput,
    }));
  };

  render() {
    const { inputProps, onChange, btnProps, btnText } = this.props;

    const { value } = inputProps;

    const { simulateInput } = this.state;

    return (
      <div className={prefix}>
        <div className={cx("content")}>
          <div className={cx("up")}>
            <div className={cx("input-wrap")}>
              <SwitchInput
                ref={this.inputRef}
                simulate={simulateInput}
                inputProps={{
                  ...inputProps,
                  // 覆盖 原始 onChange
                  onChange: (e) => onChange(e.target.value),
                }}
              />
              {inputProps.value !== "" && (
                <Icon
                  size="xs"
                  className={cx("clear-icon")}
                  type="cross-circle-o"
                  onClick={() => {
                    onChange("");
                  }}
                />
              )}

              <button className={cx("btn")} {...btnProps}>
                <div className={cx("btn-text")}>
                  {btnProps.disabled ? <Icon type="loading" /> : btnText}
                </div>
              </button>
            </div>
          </div>
          <Keyboard
            simulateInput={simulateInput}
            value={value}
            onChange={onChange}
            toggleSimulateInput={this.toggleSimulateInput}
          />
        </div>
      </div>
    );
  }
}

import React from "react";
import { Link } from "react-router-dom";
import { BgBox, AppBar } from "../components";
import completeIcon from "../images/complete_icon.png";

import qs from "qs";

import "./index.less";
import classNames from "@/util/classNames";
const prefix = "park-add-result";
const cx = classNames(prefix);

export default class extends React.Component {
	render() {
		const { search } = this.props.location;
		const obj = qs.parse(search);
		const carNumber = obj.carNumber || obj["?carNumber"];

		return (
			<BgBox>
				<div className={cx("wrap")}>
					<AppBar title="绑定成功" />
					<div className={cx("head-icon")}>
						<img src={completeIcon} width="50" />
					</div>
					<div className={cx("title")}>您已成功绑定 {carNumber}</div>

					<Link replace to="/park/add" className={cx("describe")}>
						继续绑定其他车牌
          			</Link>
					<Link to="/park" className={cx("btn")}>
						去缴费
          			</Link>
				</div>
				;
      		</BgBox>
		);
	}
}

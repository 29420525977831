import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect,Link } from 'react-router-dom';
import * as actions from './index.action';
import * as Util from "../../util/index";
import Toast from '../../components/prompt/toast';
import './index.less';

class CouponQr extends Component {
	constructor(props) {
		super(props);
		this.state={
			data:[]
		}
	}
    componentWillMount(){
	   let id=this.props.match.params.id;
	   let type = Util.getUrlArg('type');
	   this.props.getCouponInfo({id,type});
	}
	componentWillUpdate(nextProps, nextState) {
		this.state.data=nextProps.CouponQr.data;
		if(nextProps.CouponQr.data.rule){
			document.getElementsByClassName("qr-rule")[0].innerHTML=nextProps.CouponQr.data.rule;
		}
	}

	render() {
		return <div>
			<h2 className="qr_title">{this.state.data.title}</h2>
			<div className="qr-img">
				<img src={"/wx-web/public/web/getQrcode.do?content="+this.state.data.couponNo} alt=""/>
			</div>
			<p className="couponid">{this.state.data.couponNoFormat}</p>
			<p className="qr-tip">请出示立减码享受商品优惠<br/>您也可以截屏保存二维码待POS结算时出示</p>
			<div className="qr-line"></div>
			<div className="qr-rule"></div>
			<div className="qr-sendNum">{this.state.data.couponTicketNumber ? `发券小票号:${this.state.data.couponTicketNumber}`:''}</div>
			<div className="qr-useNum">{this.state.data.smallTicketNumber ? `用券小票号:${this.state.data.smallTicketNumber}`:'' }</div>
		</div>
	}
}

export default connect(
	(state) => {
		return {CouponQr:state.CouponQr}
	},
	(dispatch) => bindActionCreators({...actions}, dispatch)
)(CouponQr);
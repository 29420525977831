import React, { Component } from "react";
import Proptypes from "prop-types";

const MAX = 30;

const GetVerifyCodeBtnHoc = (WrappedComponent) => {
  class BtnComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        count: MAX,
        /**
         * 0 初始
         * 1 pending
         * 2 倒计时中
         * 3 结束 重新状态
         */
        status: 0,
      };
      this.interval = undefined;
    }

    static propTypes = {};

    updateStatus = (status) => {
      this.setState({
        status,
      });
    };

    tick = () => {
      this.setState((prev) => {
        const { count } = prev;
        if (count <= 0) {
          this.clearTick();
          return {
            count: MAX,
            status: 3,
          };
        }
        return {
          count: count - 1,
        };
      });
    };

    componentDidUpdate(_, prevState) {
      if (this.state.status === 2 && prevState.status !== 2) {
        this.interval = setInterval(this.tick, 1000);
      }
    }

    clearTick = () => {
      if (this.interval !== undefined) {
        clearInterval(this.interval);
        this.interval = undefined;
      }
    };

    componentWillUnmount() {
      this.clearTick();
    }

    render() {
      return (
        <WrappedComponent
          {...this.state}
          updateStatus={this.updateStatus}
          {...this.props}
        />
      );
    }
  }

  return BtnComponent;
};

export default GetVerifyCodeBtnHoc;

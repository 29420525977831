import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import * as actions from './index.action';
import * as Util from '../../util/index';
import Toast from '../../components/prompt/toast';
import Loader from '../../components/loader/index';
import './index.less';
import {List ,InputItem,WhiteSpace,DatePicker,TextareaItem,Radio,Flex,Picker,Tag} from 'antd-mobile';
import AreaSelector from 'react-area-selector';
import { createForm } from 'rc-form';
import arrowImg from "./image/expand.png";

let codeArr;
const Item = List.Item;

let enumData = [] ;
class Information extends Component {
	constructor(props) {
		super(props);
		this.state={

			areaData:[],
			asyncValue:[],
			cols:3,
			params:{
				address:'',
				birthday:'1980-01-01',
				cityId:'',
				customerId:'',
				decorateStatus:'',
				decorateType:'',
				districtId:'',
				houseAge:'',
				houseBetter:'',
				houseMember:'',
				houseType:'',
				income:'',
				interest:'',
				mail:'',
				provinceId:'',
				streetId:'',
				realName:'',
				sex:'',
				mobile:''
			},

			tagData:[],
			tagData2:[],

			showTagFlag:false

		}
	}
	async componentWillMount(){

		console.log(this.props,'---------props')
		let enums = await this.props.getEnums();
	
		let tags = await this.props.getTags();

		this.props.getUserInfo();	

		this.props.getAllAddress();

	}
    componentDidMount() {
		document.querySelector('body').addEventListener('touchmove',function(event){
			// event.stopPropagation();
			console.log(event.target.className)
			console.log(event.target.className === 'am-picker-popup-wrap ')
			if(event.target.className === 'am-picker-popup-wrap '){
				event.preventDefault();
			}
		}, { passive: false })
	} 
	componentWillUpdate(nextProps, nextState) {

		  //提交
		  Util.fetchCallback({
			status: nextProps.Information.submitDataStatus,
			code: nextProps.Information.submitDataCode,
			updateStatus: nextProps.updateSubmitDataStatus,
			message:nextProps.Information.submitDataMessage,
			isShowToastSuccess:true,
			successText:'提交成功',
			successCallback: () => {
				this.props.history.push('/home');
			
			}
		  });


		  //获取所有省市区
		  Util.fetchCallback({
			status: nextProps.Information.allAddressStatus,
			code: nextProps.Information.allAddressCode,
			updateStatus: nextProps.updateGetAllAddressStatus,
			message:nextProps.Information.allAddressMessage,
			successCallback: () => {
				this.setState({
					areaData:nextProps.Information.allAddressData
				});
			}
		  });

		  //获取市下面的街道
		  Util.fetchCallback({
			status: nextProps.Information.streetAddressStatus,
			code: nextProps.Information.streetAddressCode,
			updateStatus: nextProps.updateGetStreetAddressStatus,
			message:nextProps.Information.streetAddressMessage,
			successCallback: () => {
				let myData = nextProps.Information.streetAddressData;
				for(let item of myData){
					item.label = item.streetName;
					item.value = item.code;
				}

				if(myData.length){
					for(let item of this.state.areaData){
						if(item.value === codeArr[0]){
							for(let item2 of item.children){
								if(item2.value === codeArr[1]){
									for(let item3 of item2.children){
										if(item3.value === codeArr[2]){
											item3.children = myData;
											break;
										}
									}
									break;
								}
							}
							break;
						}
					}
	
					if(codeArr&&codeArr.length === 3){
						codeArr.push(myData[0].value);
					}
					this.setState({
						cols:4
					})
					
				}else {
					this.setState({
						cols:3
					})
				}
				this.setState({
					asyncValue:codeArr
				})
				
				
			}
		  });

		  //获取枚举
		  Util.fetchCallback({
			status: nextProps.Information.getEnumsStatus,
			code: nextProps.Information.getEnumsCode,
			updateStatus: nextProps.updateGetEnumsStatus,
			successCallback: () => {
				
				enumData = nextProps.Information.getEnumsData;

				let betterArr = enumData.HouseBetter;
				if(betterArr){
					for(let value of betterArr){
						value.selected = false;
					}
				}

				this.setState({
					tagData2:betterArr
				})
			
			}
		  });

		  //获取标签
		  Util.fetchCallback({
			status: nextProps.Information.getTagsStatus,
			code: nextProps.Information.getTagsCode,
			updateStatus: nextProps.updateGetTagsStatus,
			successCallback: () => {
				
				let mys = nextProps.Information.getTagsData;
				for(let value of mys){
					value.selected = false;
				}
				this.setState({
					tagData:mys
				})
			
			}
		  });

		  //获取信息
		  Util.fetchCallback({
			status: nextProps.Information.getUserInfoStatus,
			code: nextProps.Information.userInfoCode,
			updateStatus: nextProps.updateGetUserInfoStatus,
			successCallback: () => {
				
				let data = nextProps.Information.userInfoData;
				
				let myParams = {};
				for(let key in this.state.params){
					if(data[key]){
						myParams[key] = data[key];
					}
				}


				//兴趣标签高亮
				if(data.interest){
					let arr = data.interest.split(',');

					let mys = nextProps.Information.getTagsData;

					for(let item of arr){

						for(let value of mys){
							
							if(value.value == item){
								value.selected = true;
								break;
							}
						}
						
					}

					this.setState({
						tagData:mys
					})
					
				}

				//想改善的地方
				if(data.houseBetter){
					let arr = data.houseBetter.split(',');

					let mys = nextProps.Information.getEnumsData.HouseBetter;

					for(let item of arr){

						for(let value of mys){
							
							if(value.value == item){
								value.selected = true;
								break;
							}
						}
						
					}

					this.setState({
						tagData2:mys
					})
					
				}

				console.log(this.state.tagData,'1111111111')
				console.log({...this.state.params,...myParams},'222222222222222')
				//如果有 districtId 就去查询 街道
				if(data&&data.districtId){
					if(data.streetId){
						codeArr = [myParams.provinceId+'',myParams.cityId+'',myParams.districtId+'',myParams.streetId+''];
					}else {
						codeArr = [myParams.provinceId+'',myParams.cityId+'',myParams.districtId+''];
					}
					this.props.getStreetAddress({districtCode:data.districtId});
				}
				
				this.setState({
					params:{
						...this.state.params,
						...myParams
					},
					
				})
				
			}
		  })


	}

	//性别
	onChange = (value) => {
		this.setState({
			params:{
				...this.state.params,
				sex:value
			}
			
		});
	};
	handName=(e)=>{
		
		this.setState({
			params:{
				...this.state.params,
				realName:e
			}
		});
	}
	saveInfo=(e)=>{
		e.preventDefault();
		
		this.props.form.validateFields({force:true},(error)=>{

			console.log(error,'---------error');
			if(!error){
				if(!this.state.params.realName.trim()){
					alert('请填写姓名');
					return;
				}


				let formData = this.props.form.getFieldsValue();

				let tagStr = '';

				for(let item of this.state.tagData){
					if(item.selected){
						tagStr = tagStr + item.value +",";
					}
				}

				tagStr = tagStr.substr(0,tagStr.length - 1);


				let tagStr2 = '';

				for(let item of this.state.tagData2){
					if(item.selected){
						tagStr2 = tagStr2 + item.value +",";
					}
				}

				tagStr2 = tagStr2.substr(0,tagStr2.length - 1);

				this.setState({
					params:{
						...this.state.params,
						mail:formData.email,
						interest:tagStr,
						houseBetter:tagStr2
					}
				})


				this.props.submitData({
					...this.state.params,
					mail:formData.email,
					interest:tagStr,
					houseBetter:tagStr2
				});
				console.log(this.state.params,'---------params')
				setTimeout(()=>{
					console.log(this.state.params,'----dddddd--params')
				}
				,1000)


			}else {
				alert("请填写正确的电子邮箱地址");
				console.log('失败啦-----')
			}
		})

	}

	//处理 日期
	handleDate=(d)=>{
	
		function formatDate(date) {
			
			const pad = n => n < 10 ? `0${n}` : n;
			const dateStr = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
			return `${dateStr}`;
		  }

		  console.log(d,'--------------d')

		this.setState(
			{
				params:{
					...this.state.params,
					birthday:formatDate(d)
				}
			}
		)
	
	}

	tagChange = (value,name)=>{
		let that = this;
		return function(){

			let num = 0;
			for(let obj of that.state[name]){
				if(obj.selected){
					num += 1;
				}
			}	


			for(let obj2 of that.state[name]){
				if(obj2.value === value){
					if(obj2.selected || num < 5){
						obj2.selected = !obj2.selected;

					}else if(num === 5 && !obj2.selected){
						alert('最多选择5个标签');
						return;
					}

					let dpObj = {};
					dpObj[name] = that.state[name];

					that.setState({
						...dpObj
					})
					break;
				} 
			}
				
			

			
		}
	}  

	showMoreTags = ()=>{
		this.setState({
			showTagFlag:!this.state.showTagFlag
		})
	}


	provincesChange = (val) =>{
		console.log(val,'6666666666666666666666');
		this.setState({params:{...this.state.params,provinceId: val[0],cityId:val[1] ? val[1] : '',districtId:val[2] ? val[2] : '',streetId:val[3] ? val[3] : ''}})
	}

	provincesPickerChange = (val)=>{
		console.log(val,'555555555555');
		if(val.length === 3){
			codeArr = val;
			this.props.getStreetAddress({districtCode:val[val.length -1]});
		}
	}

	render() {
		const data = [
			{ value: 1, label: '男' },
			{ value: 2, label: '女' },
		];
		let familyIncome = [];
		let houseType = [];
		let houseMember = [];
		let houseAge = [];
		let decorateStatus = [];
		let decorateType = [];

		if(enumData.Income){
			familyIncome = enumData.Income;
		}

		if(enumData.HouseType){
			houseType = enumData.HouseType;
		}

		if(enumData.HouseMember){
			houseMember = enumData.HouseMember;
		}

		if(enumData.HouseAge){
			houseAge = enumData.HouseAge;
		}

		if(enumData.DecorateStatus){
			decorateStatus = enumData.DecorateStatus;
		}
		if(enumData.DecorateType){
			decorateType = enumData.DecorateType;
		}
		

		
		const { getFieldProps, getFieldError } = this.props.form;
		
		return (
			<div>
				<div className='dp-tip-text'>完善资料可获取100积分哦</div>
				<Loader spinning={this.props.FetchState.spinning} />
				<form>	
				<List>
					<Item className='dp-title'>基本信息</Item>
					<InputItem
						value={this.state.params.mobile}
						disabled
					></InputItem>
					<InputItem
						value={this.state.params.realName}
						onChange={(e)=>{this.handName(e)}}
						data-seed="logId"
						placeholder='您的姓名'
						maxLength='30'
						className={this.state.params.realName ? null : 'dp-name'}
					></InputItem>
				  <Item>
					  {data.map(i => (
                         <Radio key={i.value} checked={this.state.params.sex === i.value} onChange={() => this.onChange(i.value)}>
                            {i.label}
                         </Radio>
                       ))}
				  </Item>
	
					<DatePicker
					   mode="date"
					   format = "YYYY-MM-DD"
					   minDate={new Date(1900, 1, 1, 0, 0, 0)}
                       title="请选择"
					   value={new Date(this.state.params.birthday)}
					   onChange={date => this.handleDate(date)}
					   >
					  <List.Item arrow="horizontal">您的生日</List.Item>
                    </DatePicker>
					<InputItem
						{...getFieldProps('email', {
							initialValue: this.state.params.mail,
							rules: [
							  { type:'email', message: '请填写正确的电子邮箱地址'},
							],
						  })}
						  error={!!getFieldError('email')}
						  onErrorClick={() => {
							alert(getFieldError('email').join('、'));
						  }}
						placeholder='邮箱地址'
						maxLength='60'
					></InputItem>
					<Picker 
					data={familyIncome} 
					value={[this.state.params.income]} 
					onChange={v => this.setState({params:{...this.state.params,income: v[0] }})}
					cols={1} 
					className="forss">
        			  <List.Item arrow="horizontal">家庭收入</List.Item>
        			</Picker>

					<Picker 
					  data={this.state.areaData}
					  cols={this.state.cols}
					//   value={[this.state.params.provinceId+'',this.state.params.cityId+'',this.state.params.districtId+'']}
					  value={this.state.asyncValue}
					  onChange={this.provincesChange}
					  onPickerChange={this.provincesPickerChange}
        			>
        			  <List.Item arrow="horizontal">收货地址省市区</List.Item>
        			</Picker>
					<InputItem
						value={[this.state.params.address]}
						onChange={v => this.setState({params:{...this.state.params,address: v }})}
						maxLength='120'
						placeholder='收货详细地址'
						className='dp-adress'
					></InputItem>
					<Item className='dp-tags'>
						<div className='dp-tag-title'>选择您所感兴趣的标签，最多选择5个</div>
						<div className={this.state.showTagFlag ? 'dp-choose-tag dp-newHeight' : 'dp-choose-tag'}>
							{
								this.state.tagData.map((item)=>{
									return (
										<Tag key={item.value} className={item.selected ? 'dp-tag-active' : 'dp-tag-normal'} onChange={this.tagChange(item.value,'tagData')}>{item.label}</Tag>
									)	
								})
							}
							<img src={arrowImg} className={this.state.showTagFlag ? 'dp-tag-img dp-rotate' : 'dp-tag-img'} onClick={this.showMoreTags}/>
						</div>
					</Item>
					<Item className='dp-title'>居住状态</Item>
					<Picker 
					data={houseType} 
					value={[this.state.params.houseType]} 
					onChange={v => this.setState({params:{...this.state.params,houseType: v[0] }})}
					cols={1} 
					className="forss">
        			  <List.Item arrow="horizontal">户型</List.Item>
        			</Picker>
					<Picker 
					data={houseMember}
					value={[this.state.params.houseMember]} 
					onChange={v => this.setState({params:{...this.state.params,houseMember: v[0] }})}
					cols={1} 
					className="forss">
        			  <List.Item arrow="horizontal">人口</List.Item>
        			</Picker>
					<Picker 
					data={houseAge} 
					value={[this.state.params.houseAge]} 
					onChange={v => this.setState({params:{...this.state.params,houseAge: v[0] }})}
					cols={1} 
					className="forss">
        			  <List.Item arrow="horizontal">房龄</List.Item>
        			</Picker>
					<Item className='dp-title'>装修信息</Item>
					<Picker 
					data={decorateStatus} 
					value={[this.state.params.decorateStatus]}
					onChange={v => this.setState({params:{...this.state.params,decorateStatus: v[0] }})}
					cols={1} 
					className="forss">
        			  <List.Item arrow="horizontal">装修状态</List.Item>
        			</Picker>
					<Picker 
					data={decorateType} 
					value={[this.state.params.decorateType]}
					onChange={v => this.setState({params:{...this.state.params,decorateType: v[0] }})}
					cols={1} 
					className="forss">
        			  <List.Item arrow="horizontal">装修类型</List.Item>
        			</Picker>
					<Item className='dp-tags'>
						<div className='dp-tag-title'>目前房屋想改善的地方,多选</div>
						<div className='dp-choose-tag dp-choose-tag2'>
							{
								this.state.tagData2.map((item)=>{
									return (
										<Tag key={item.value} className={item.selected ? 'dp-tag-active' : 'dp-tag-normal'} onChange={this.tagChange(item.value,'tagData2')}>{item.label}</Tag>
									)	
								})
							}
						</div>
					</Item>
					<div className="save-info"><button onClick={this.saveInfo}>保存</button></div>
					<WhiteSpace />
				</List>
				</form>
			</div>
		);
	}
}

const FormWrapper = createForm()(Information);

export default connect(
	(state) => {
		return {Information: state.Information,FetchState:state.Fetch}
	},
	(dispatch) => bindActionCreators({...actions}, dispatch)
)(FormWrapper);
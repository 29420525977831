export let getCarousel = (argus) => {
	return {
		type: 'GET_CAROUSEL',		
		playload: {
			url: '/public/banner/getImg',
            type: 'get',
            isService:true,
			param: {				
				...argus			
			}
		}
	}
}

export let updateGetCarouselStatus = () => {
	return {
		type: 'UPDATE_GET_CAROUSEL_STATUS'
	}
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import './index.less';

class Bar extends Component {
	constructor(props) {
		super(props);
		this.state={
		
		}
	}
	componentWillUpdate(nextProps, nextState) {
		
	}
	render() {
        const {title,imgSrc,href} = this.props;
		return (
                <div className="btn_wrap">
		   <Link to={href}><img src={imgSrc} alt=""/></Link>	
                    <p>{title}</p> 
                </div>
		);
	}
}

export default Bar;
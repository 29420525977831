import React, { Component } from "react";
import Proptypes from "prop-types";

import { Toast } from "antd-mobile";

import request from "../request";
import PRIZE from "../prize";
import { prizeMayAuth } from "../storageManager";

import "./win.less";

import Wrap from "./Wrap";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.apiUrl = "/wx-web/public/thw/lotteryConfirmOrCancel";
  }

  static propTypes = {
    couponNo: Proptypes.string,
    lotteryNo: Proptypes.string,
    getLottery: Proptypes.func,
    handleModal: Proptypes.func,
  };

  getBody = (type) => {
    const { phone, token } = prizeMayAuth.getItem() || {};
    const { couponNo } = this.props;
    return {
      data: {
        phone,
        token,
        couponNo,
        type,
      },
    };
  };

  handleLottery = async (type) => {
    const options = {
      method: "POST",
      body: JSON.stringify(this.getBody(type)),
    };

    try {
      Toast.loading("请稍后...", 0);

      const json = await request(this.apiUrl, options);

      const { code, msg } = json || {};

      if (type === 1) {
        // 1确认
        if (code != "200") {
          const error = new Error(msg);
          error.response = json;
          throw error;
        }
        this.props.handleModal("notify");
      }

      if (type === 2) {
        // 2取消
        this.props.handleModal(0); // 关闭弹窗
        this.props.getLottery();
      }

      Toast.hide();
    } catch (error) {
      Toast.hide();

      const { response: { code } = {} } = error || {};

      if (code == "404") {
        this.props.handleModal("had");
      }
    }
  };

  render() {
    const prizeImg =
      PRIZE[this.props.lotteryNo] && PRIZE[this.props.lotteryNo].img;

    return (
      <Wrap {...this.props}>
        <div className="win">
          <img src={prizeImg} />
          <div className="btn-wrap">
            <div className="btn" onClick={() => this.handleLottery(1)}>
              领取奖品
            </div>
            <div className="btn" onClick={() => this.handleLottery(2)}>
              放弃, 再来一次
            </div>
          </div>
        </div>
      </Wrap>
    );
  }
}

export default Modal;

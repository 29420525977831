import React from "react";
import Proptypes from "prop-types";

import "./index.less";

const prefix = "park-bg-box";

const BgBox = ({ children, className }) => {
  return <div className={`${prefix} ${className}`}>{children}</div>;
};

BgBox.defaultProps = {
  className: "",
};

BgBox.propTypes = {
  className: Proptypes.string,
};

export default BgBox;

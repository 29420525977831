import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./index.action";
import * as Util from "../../util/index";
import * as __URL__ from "../../../config/index";
import { Button, WingBlank, Checkbox, Toast, Modal, Flex } from "antd-mobile";
import Loader from "../../components/loader/index";
import "./index.less";
import LogoImg from "./images/logo.png";
import lock from "./images/lock.png";
import iphone from "./images/iphone.png";
import close from "./images/close.png";
import md5 from "js-md5";
import moment from "moment";

const AgreeItem = Checkbox.AgreeItem;
const ENV = process.env.NODE_ENV;
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      verifyCode: "",
      imgCode: "",
      btnVal: "获取验证码",
      checked: true,
      spinning: false,
      isShow: false,
      random: Math.random(),
      modal1: false,
      disabled: false,
      imgSrc: "",
      openId: "",
    };
  }

  login = () => {
    let mobile = this.state.mobile;
    let verifyCode = this.state.verifyCode;
    let from = 1;
    let isAgree = this.state.checked ? 1 : 0;
    let openId = this.state.openId;
    this.props.loginUser({
      mobile: mobile,
      verifyCode: verifyCode,
      isAgree: isAgree,
      from: from,
      openId: Util.getUrlArg("key"),
      needlessCookie: true
    });
  };
  handleMobileChange = (e) => {
    let value = e.target.value;
    this.setState({
      mobile: value,
    });
  };
  handleImgChange = (e) => {
    let value = e.target.value;
    this.setState({
      imgCode: value,
    });
  };
  handleCodeChange = (e) => {
    let value = e.target.value;
    this.setState({
      verifyCode: value,
    });
  };
  openModel = () => {
    if (this.state.mobile == "") {
      Toast.show("手机号码不正确");
      return false;
    }
    let targetUrl = __URL__[ENV]["targetUrl"];
    this.setState({
      imgCode: "",
      isShow: true,
      imgSrc: `${targetUrl}/wx-web/public/web/getValidateCode.do?imgToken=${
        this.state.mobile
        }&random=${Math.random()}`,
    });
  };
  closeModel = (e) => {
    e.preventDefault();
    this.setState({ isShow: false });
  };
  onClose = (key) => () => {
    this.setState({
      [key]: false,
    });
  };
  onOpen = (key) => (e) => {
    this.setState({
      [key]: true,
    });
  };
  refeshCode = () => {
    let targetUrl = __URL__[ENV]["targetUrl"];
    this.setState({
      imgSrc: `${targetUrl}/wx-web/public/web/getValidateCode.do?imgToken=${
        this.state.mobile
        }&random=${Math.random()}`,
    });
  };
  sendCode = () => {
    let mobile = this.state.mobile,
      imgCode = this.state.imgCode;
    this.props.getCode({
      mobile: mobile,
      imgCode: imgCode,
    });
  };
  componentWillMount() {
    let code = Util.getUrlArg("code");
    if (code) {
      this.props.wxLoginUser({
        code,
      });
    }

    let flag = Util.getUrlArg("flag") == "true";
    let openid = Util.getUrlArg("key");
    let str = "vkhtbhm5u23u4f50rcdv0mo8l5444";
    let time = moment().format("YYYYMMDD");
    if (flag && openid) {
      this.props.wechartLogin({
        openid: openid,
        sign: md5(openid + str + time),
      });
    }
  }

  componentDidMount() {
    const inp = document.getElementsByTagName("input");

    Array.from(inp).map((item) => {
      item.onblur = () => {
        window.scrollTo(0, 0);
      };
    });
  }
  componentWillUpdate(nextProps, nextState) {
    // 短信验证码
    Util.fetchCallback({
      status: nextProps.Login.verifyStatus,
      code: nextProps.Login.verifyCode,
      message: nextProps.Login.verifyMessage,
      updateStatus: nextProps.updateGetCodeStatus,
      successCallback: () => {
        Toast.show(nextProps.Login.verifyMessage);
        this.setState({ isShow: false });
      },
    });
    // 登陆
    Util.fetchCallback({
      status: nextProps.Login.loginStatus,
      code: nextProps.Login.loginCode,
      message: nextProps.Login.loginMessage,
      updateStatus: nextProps.updateLoginUserStatus,
      successCallback: () => {
        Toast.show(nextProps.Login.loginMessage);

        let url = Util.getUrlArg("toUrl");
        const redirectUrl = Util.getUrlArg("redirectUrl");

        if (url) {
          window.location.href = url;
        } else if (redirectUrl) {
          this.props.history.replace(redirectUrl);
        } else {
          this.props.history.replace("/home");
        }
      },
    });

    //微信信息
    Util.fetchCallback({
      status: nextProps.Login.wxLoginStatus,
      code: nextProps.Login.wxLoginCode,
      message: nextProps.Login.wxLoginMessage,
      updateStatus: nextProps.updateWxLoginStatus,
      successCallback: () => {
        // Toast.show(nextProps.Login.loginMessage);
        // this.props.history.push('/home');

        let myData = nextProps.Login.wxLoginData;

        this.setState({
          openId: myData ? myData : "",
        });
      },
    });

    //微信登陆
    Util.fetchCallback({
      status: nextProps.Login.wechartLoginStatus,
      code: nextProps.Login.wechartLoginCode,
      message: nextProps.Login.wechartLoginMessage,
      updateStatus: nextProps.updateWechartLoginStatus,
      successCallback: () => {
        let code = nextProps.Login.wechartLoginCode;
        if (code == 200) {
          this.props.history.push("/home");
        } else {
          Toast.show(nextProps.Login.wechartLoginMessage);
        }
      },
    });
  }
  render() {
    return (
      <div>
        <div className="login-logo">
          <img src={LogoImg} alt="" />
        </div>
        <WingBlank>
          <div className="input-div dp-div">
            <img src={iphone} alt="" />
            <input
              type="phone"
              placeholder="输入手机号"
              value={this.state.mobile}
              onChange={this.handleMobileChange}
            />
          </div>
          <div className="input-div">
            <img src={lock} alt="" />
            <input
              type="text"
              placeholder="输入验证码"
              value={this.state.verifyCode}
              onChange={this.handleCodeChange}
            />
            <button className="get-code" onClick={this.openModel}>
              {this.state.btnVal}
            </button>
          </div>
          <div className="input-div">
            <AgreeItem
              data-seed="logId"
              checked={this.state.checked}
              onChange={(e) => this.setState({ checked: !this.state.checked })}
            >
              已阅读并同意
              <a
                onClick={(e) => {
                  e.preventDefault();
                  this.onOpen("modal1")();
                }}
              >
                《会员注册协议》{" "}
              </a>
            </AgreeItem>
          </div>
          <div className="dp-input-div">
            <button className="login-btn" onClick={this.login}>
              登录
            </button>
          </div>
        </WingBlank>
        <Loader
          spinning={this.props.FetchState.spinning || this.state.spinning}
        />
        <div
          className="popupCode"
          style={{ display: this.state.isShow ? "block" : "none" }}
        >
          <div className="popup_div">
            <div className="dp-title">
              请输入图形验证码
              <img src={close} onClick={this.closeModel} alt="" />
            </div>
            <div className="input_div">
              <input
                placeholder="输入图片中的字符"
                type="text"
                value={this.state.imgCode}
                onChange={this.handleImgChange}
              />
              <img
                alt="点击刷新"
                onClick={this.refeshCode}
                src={this.state.imgSrc}
              />
            </div>
            <Flex className="btn_div">
              <Flex.Item>
                <Button className="cancel_code" onClick={this.closeModel}>
                  取消
                </Button>
              </Flex.Item>
              <Flex.Item>
                <Button
                  className="send_code"
                  onClick={this.sendCode}
                  disabled={this.state.disabled}
                >
                  确定
                </Button>
              </Flex.Item>
            </Flex>
          </div>
        </div>
        <WingBlank>
          <Modal
            visible={this.state.modal1}
            transparent
            maskClosable={false}
            onClose={this.onClose("modal1")}
            title="百安居会员协议"
            footer={[
              {
                text: "确定",
                onPress: () => {
                  this.onClose("modal1")();
                },
              },
            ]}
            wrapProps={{ onTouchStart: this.onWrapTouchStart }}
          >
            <div style={{ height: 150, overflow: "scroll", textAlign: "left" }}>
              百安居（中国）投资有限公司及其关联公司（下称"百安居"）对百安居的产品及服务制定了以下协议（以下统称为"本协议"）。凡注册成为百安居会员，接受服务之前，都必须理解并接受本协议的全部内容，并遵循本协议之所有约定。
              <br />
              <br /> 1.会员申办
              <br />
              <br />{" "}
              顾客自愿注册成为百安居会员，领取百安居会员卡，享受百安居购物积分、会员商品、专享服务、互动增值等服务
              <br />
              <br /> 2.注册信息
              <br />
              <br />{" "}
              1)会员在办理入会申请时，勾选"同意"即表示同意本协议的所有内容；
              <br />
              <br />{" "}
              2)会员提供的注册信息应真实、准确、合法，注册信息如有变动，会员应及时更新；
              <br />
              <br />{" "}
              如会员提供的注册信息不真实、准确、会员需承担因此引起的责任及后果，并且百安居保留终止会员使用百安居会员各项服务的权利。
              <br />
              <br />{" "}
              3)会员注册信息将用于会员卡的发放，专用于百安居会员项目办理。未经会员允许，百安居不会将会员的个人信息透漏给第三方公司（百安居合法委托的向会员发送百安居销售、产品信息等的第三方公司及百安居关联公司除外）。但是，会员需要了解，百安居将有可能被权威机构，尤其是法律机构强制要求提供所述信息。
              <br />
              <br /> 3.会员账号
              <br />
              <br />{" "}
              1)会员应妥善保管账号、用户名和密码，并将对用户名和密码安全负责，会员应对使用自己账号和密码而产生的一切行为承担责任；
              <br />
              <br />{" "}
              2)会员忘记用户名、账号和密码的，可以通过注册时使用的手机号码找回；
              <br />
              <br />{" "}
              3)会员若发现账号、密码被盗或得知正在被第三方使用时，应立即通知百安居。
              <br />
              <br />{" "}
              4)因违反本条第1项至第3项中的任何一项规定而造成会员或第三方损失时，百安居不承担任何责任。
              <br />
              <br /> 4.会员服务
              <br />
              <br /> 顾客注册成为百安居会员后，可享受下述服务：
              <br />
              <br /> 1)百安居购物积分、会员商品、专享服务、互动增值；
              <br />
              <br />{" "}
              2)会员自愿以电子邮件、短信、电话或其他方式接受来自百安居的产品、服务和活动信息。
              <br />
              <br /> 5.会员卡
              <br />
              <br />{" "}
              1)会员卡是会员在百安居使用权益有效凭证，请妥善保管会员卡，在使用时出示；
              <br />
              <br /> 2)会员卡一经办领，不退不换；
              <br />
              <br />{" "}
              3)会员卡和会员积分仅限会员本人使用。会员不得将自己的会员权利向第三方进行转让、出售、过户、抵押或用来提供其他担保等。
              <br />
              <br />{" "}
              4)对于伪造、变造、冒用会员卡的行为，百安居保留追究其法律责任的权利。
              <br />
              <br /> 6.会员电子优惠券
              <br />
              <br />{" "}
              会员电子优惠券是百安居通过微信、微博、官网、APP、SMS、EDM、直邮等形式发放给会员，仅限会员本人使用，存放于会员个人账户，不兑现，不找赎的折扣凭证，会员电子优惠券使用规则如下：
              <br />
              <br />{" "}
              1)会员电子优惠券仅限会员本人使用，不可转赠，出售，兑换现金，如经发现并证实，该优惠券将予以作废处理；
              <br />
              <br />{" "}
              2)会员电子优惠券仅限授权商品范围使用，部分特价品、清仓商品不可使用会员电子优惠券；
              <br />
              <br />{" "}
              3)会员电子优惠券必须在有效期内使用，过期作废，会员电子优惠券中，满减性质的优惠券不能兑换现金；
              <br />
              <br />{" "}
              4)使用会员电子优惠券支付的交易，发生退货时使用会员电子优惠券的金额仍以会员电子优惠券的形式退回给会员，不能用来兑换现金，不找零；
              <br />
              <br /> 7．其他
              <br />
              <br />{" "}
              1)本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律。
              <br />
              <br />{" "}
              2)会员与百安居之间就本协议产生的一切纠纷应由百安居所在地（上海市浦东新区银霄路393号）有管辖权的人民法院管辖。
              <br />
              <br />
            </div>
          </Modal>
        </WingBlank>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return { Login: state.Login, FetchState: state.Fetch };
  },
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(Login);

import React from "react";
import Proptypes from "prop-types";

import { Icon } from "antd-mobile";

import classNames from "../../../../util/classNames";

import PROVINCE from "./province";
import CITY from "./city";

import "./index.less";

const prefix = "park-car-input-options";

const cx = classNames(prefix);

const CloseIcon = (props) => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="close"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
    {...props}
  >
    <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
  </svg>
);

export default class CarInputOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      province: "",
      city: "",
      hint: false,
    };

    this.inputRef = React.createRef();
  }

  static propTypes = {
    /**
     * 不要与 inputProps里的 onChange 混淆
     * 提交时 使用 参数里面的 whole
     */
    onChange: Proptypes.func.isRequired,
    btnText: Proptypes.string.isRequired,
    submitting: Proptypes.bool.isRequired,
    inputProps: Proptypes.object,
    btnProps: Proptypes.object,
    initialValue: Proptypes.string,
  };

  static defaultProps = {
    inputProps: {},
    btnProps: {},
    submitting: false,
    initialValue: "",
  };

  _canDel = false;

  onKeyUp = (e) => {
    const { city, province } = this.state;

    const {
      inputProps: { value },
    } = this.props;

    if (e.keyCode === 8 && value === "" && this._canDel) {
      if (city !== "") {
        this.setState({ city: "" });
      } else {
        if (province !== "") {
          this.setState({ province: "" });
        }
      }
    }
    this._canDel = !value;
  };

  onFocus = () => {
    const {
      inputProps: { value },
    } = this.props;

    this._canDel = !value;

    this.hintOptions();
  };

  hintOptions = () => {
    const { province, city } = this.state;
    if (province === "" || city === "") {
      this.setState({
        hint: true,
      });
    } else {
      this.setState({
        hint: false,
      });
    }
    this.inputRef.current.focus();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      inputProps: { value: prevValue },
    } = prevProps;

    const { inputProps: { value = "" } = {} } = this.props;

    const { province, city } = this.state;

    const { province: prevProvince, city: prevCity } = prevState;

    const updated =
      province !== prevProvince || city !== prevCity || value !== prevValue;

    if (updated) {
      this.hintOptions();
      const result = {
        province,
        city,
        value: value.trim(),
        whole: `${province}${city}${value.trim()}`,
      };

      // test
      const ENV = process.env.NODE_ENV || "production";

      if (ENV !== "production") {
        console.log("CarInputOptions", result);
      }

      this.props.onChange(result);
    }
  };

  render() {
    const { province, city, hint } = this.state;

    const { inputProps, btnProps, submitting, btnText } = this.props;

    const { value } = inputProps;

    return (
      <div className={prefix}>
        <div className={cx("content")}>
          <div className={cx("hint", { "hint-show": hint })}>
            <div className={cx("hint-content")}>
              <div className={cx("hint-tip")}>
                可从下面选择
                <br />
                (或全部手动输入)
              </div>
              <div>
                <div className={cx("hint-list")}>
                  {PROVINCE.map(({ name }) => (
                    <div
                      key={name}
                      onClick={() => {
                        this.setState({ province: name }, this.hintOptions);
                      }}
                      className={cx("hint-item", {
                        "hint-item-select": province === name,
                      })}
                    >
                      {name}
                    </div>
                  ))}
                </div>
                <div className={cx("hint-list")}>
                  {CITY.map((item, i) => {
                    const name = item.toUpperCase();
                    return (
                      <div
                        key={name}
                        onClick={() => {
                          this.setState({ city: name }, this.hintOptions);
                        }}
                        className={cx("hint-item", {
                          "hint-item-select": name === city,
                        })}
                      >
                        {name}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={cx("up")}>
            <div className={cx("input-wrap")}>
              <div
                onClick={() => this.setState({ province: "" })}
                className={cx("tag", { "tag-show": province })}
              >
                {province}
                <CloseIcon className={cx("close-icon")} />
              </div>
              <div
                onClick={() => this.setState({ city: "" })}
                className={cx("tag", { "tag-show": city })}
              >
                {city}
                <CloseIcon className={cx("close-icon")} />
              </div>
              <input
                ref={this.inputRef}
                onFocus={this.onFocus}
                onKeyUp={this.onKeyUp}
                className={cx("input")}
                {...inputProps}
              />
              {value !== "" && (
                <Icon
                  size="xs"
                  onClick={() => {
                    const { onChange } = inputProps;
                    onChange && onChange({ target: { value: "" } });
                  }}
                  className={cx("clear-icon")}
                  type="cross-circle-o"
                />
              )}
              <button className={cx("btn")} {...btnProps}>
                <div className={cx("btn-text")}>
                  {submitting ? <Icon type="loading" /> : btnText}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let initState = {
	
}
export default function(state=initState, action) {
	switch(action.type) {
		case 'BINDPHONE':
			return {
				...state,
				bindPhoneStatus: true,
				bindPhoneCode: action.playload.code,
				bindPhoneMessage: action.playload.message,
				bindPhoneData:action.playload.data
			}
			break;
		case 'BINDPHONE_FAIL':
			return {
				...state,
				bindPhoneStatus: true,
				bindPhoneCode: action.playload.code,
				bindPhoneMessage: action.playload.message
			}	
			break;
		case 'UPDATE_BINDPHONE':
			return {
				...state,
				bindPhoneStatus: false
			}
			break;
		case 'GETCOMPANYENUM':
			return {
				...state,
				getCompanyStatus: true,
				getCompanyCode: action.playload.code,
				getCompanyMessage: action.playload.message,
				getCompanyData:action.playload.data
			}
			break;
		case 'GETCOMPANYENUM_FAIL':
			return {
				...state,
				getCompanyStatus: true,
				getCompanyCode: action.playload.code,
				getCompanyMessage: action.playload.message
			}	
			break;
		case 'UPDATE_GETCOMPANY':
			return {
				...state,
				getCompanyStatus: false
			}
			break;
		default:
			return state;
	}
}
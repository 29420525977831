import * as __URL__ from "../../../config/index";
import * as Util from "../../util/index";

export default async (args) => {
  //判断当前环境
  const ENV = process.env.NODE_ENV;
  let params = args.type.toUpperCase() === "GET" ? null : args.param;
  let url = args.type.toUpperCase() === "GET" ? Util.createUrl(args) : args.url;
  let requestUrl = __URL__[ENV]["apiUrl"] + __URL__[ENV]["apiUrlFilter"] + url;
  args.isService
    ? (requestUrl =
      __URL__[ENV]["apiUrl"] + __URL__[ENV]["serviceFilter"] + url)
    : "";
  // let requestUrl = __URL__[ENV]['apiUrl']  + url;
  let token = Util.getCookie("sessionToken");
  let headers = {
    Accept: "application/json",
    "Content-Type": args.contentType || "application/json",
  };

  console.log(token, 'token');

  const needlessCookie = params ? Object.keys(params).includes('needlessCookie') : false;
  let dpToken = localStorage.getItem("sessionToken");
  if (!needlessCookie) {
    if (token) {
      headers["sessionToken"] = token;
      localStorage.setItem("sessionToken", token);
    } else if (dpToken) {
      headers["sessionToken"] = dpToken;
    }
  }

  requestUrl = handleProtocol(requestUrl);

  return fetch(requestUrl, {
    credentials: "include", // 请求带上cookies，是每次请求保持会话一直
    method: args.type.toUpperCase(),
    follow: 1,
    timeout: 10000,
    headers: headers,
    body: params ? JSON.stringify({ data: params }) : null,
  }).then((response) => response.json());
};

function handleProtocol(url) {
  const href = window.location.href;
  const isHttps = /^https/.test(href);
  if (isHttps) {
    const start = url.slice(4);
    return `https${start}`;
  }

  return url; // 不变
}

const options = {
  enableHighAccuracy: true,
  timeout: 1000 * 5,
  maximumAge: 0,
};

export default () => {
  return new Promise((resolve, reject) => {
    function success(pos) {
      console.log('pos：', pos);
      resolve(pos);
    }

    function error(err) {
      const { message } = err;
      reject({ message });
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      reject({ message: '当前浏览器不支持当前地址位置信息获取.' });
    }
  });
};

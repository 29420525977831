import React, { Component } from "react";
import Proptypes from "prop-types";

import "./UseUp.less";

import img from "../images/useup.png";

import Wrap from "./Wrap";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {};

  render() {
    return (
      <Wrap {...this.props}>
        <div className="useup">
          <img src={img} />
          <div className="btn" onClick={() => this.props.handleModal(0)}>
            明天再来
          </div>
        </div>
      </Wrap>
    );
  }
}

export default Modal;

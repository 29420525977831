import * as __URL__ from "../../../config/index";

const ENV = process.env.NODE_ENV;

const getBase = () => {
  let result = __URL__[ENV]["targetUrl"];

  if (ENV === "production") {
    result = "https://wxadmin.market.bnq.com.cn";
  }
  return result;
};

export const BASE = getBase();

const parseJSON = (response) => {
  return response.json();
};

export default (url, options = {}) => {
  const apiUrl = new URL(url, BASE);

  const defaultOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(apiUrl.href, { ...defaultOptions, ...options }).then(parseJSON);
};

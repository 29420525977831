export let editPhone = (argus) => {
	return {
		type: 'EDIT_BINDPHONE',		
		playload: {
			url: '/employee/entrance/update.do',
			type: 'post',
			param: {				
				...argus			
			}
		}
	}
}

export let updateGetResStatus = () => {
	return {
		type: 'UPDATE_EDIT_BINDPHONE'
	}
}

export let getCompanyEnum = (argus) => {
	return {
		type: 'GETCOMPANYENUM',		
		playload: {
			url: '/employee/entrance/getEnum',
			type: 'get',
			param: {				
				...argus			
			}
		}
	}
}

export let updateGetCompanyEnumStatus = () => {
	return {
		type: 'UPDATE_GETCOMPANY'
	}
}


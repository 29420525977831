import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pagination from 'rc-pagination';
import { Button } from 'antd-mobile';
import * as Util from '../../util/index';
import * as actions from './index.action';
import './index.less';

class Show extends Component {
    state = {
        flag: true,
        name: '关闭自动刷新',
        table: [],
        current: 1,
    };

    componentWillMount(){
        this.props.getScanList({
            curPage: this.state.current,
            pageSize: 10
        });
    }

    componentDidMount() {
        this.timer=setInterval(() => {
            this.props.getScanList({
                curPage: 1,
                pageSize: 10
            });
        }, 2000)
    }

    componentWillUnmount() {
        this.timer && clearInterval(this.timer);
      }

    componentWillUpdate (nextProps, nextState) {
        Util.fetchCallback({
            status: nextProps.Show.tableStatus,
            code: nextProps.Show.tableCode,
            message: nextProps.Show.tableMessage,
            updateStatus: nextProps.updateWxScanListStatus,
            successCallback: () => {
                let myData = nextProps.Show.tableData;
                this.setState({
                    table: myData
                })
            }
        });
    }
 
    onChange = page => {
        this.setState({
            current: page,
            flag: false,
            name: '开启自动刷新' 
        });
        this.props.getScanList({
            curPage: page,
            pageSize: 10
        });
        this.timer && clearInterval(this.timer);
    };

    refresh = () => {
        const { flag } = this.state;
        this.setState({
            flag: !flag,
            name: flag ? '开启自动刷新' : '关闭自动刷新' 
        },() => {
            if(flag !== true){
                this.timer=setInterval(() => {
                    this.props.getScanList({
                        curPage: 1,
                        pageSize: 10
                    });
                }, 2000)
            }else{
                this.timer && clearInterval(this.timer);
            }
        })
    }

    renderTable = (data) => {
        return data.map((item)=> {
            return (
                <Fragment>
                    <tr>
                        <td>{item.employeeNo}</td>
                        <td>{item.employeeName}</td>
                        <td>{item.mobile}</td>
                        <td>{item.originName}</td>
                        <td>{item.dateCreatedStr}</td>
                    </tr>
                </Fragment>
            )
        })
        
    }

    render(){
        const total = this.props.Show && this.props.Show.tablePage && this.props.Show.tablePage.totalNum
        return (
            <div className="table-scan-wrap">
                <h1 className="table-scan-title">门禁记录</h1>
                <hr/>
                <Button
                    type="primary"
                    inline
                    style={{ marginRight: '4px',textAlign: "left", cursor: 'pointer' }}
                    onClick={this.refresh}
                >{this.state.name}</Button>
                <table className="table-scan">
                    <tbody>
                    <tr>
                        <td>员工号</td>
                        <td>姓名</td>
                        <td>手机号</td>
                        <td>公司名</td>
                        <td>进入时间</td>
                    </tr>
                    {this.renderTable(this.state.table)}
                    </tbody>
                </table>
                <Pagination defaultCurrent={1} current={this.state.current} onChange={this.onChange} total={total} />
            </div>
            
        )
    }
}

export default connect(
	(state) => {
		return {Show: state.Show,FetchState: state.Fetch}
	},
	(dispatch) => bindActionCreators({...actions}, dispatch)
)(Show);
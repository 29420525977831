import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect, Link } from "react-router-dom";
import * as actions from "./index.action";
// import Toast from "../../components/prompt/toast";
import "./index.less";
import coupon from "./imgages/no-coupon.png";
import ticket from "./imgages/ticket.png";
import * as Util from "../../util/index";
import { Checkbox, Button, Modal, Toast, Tabs } from "antd-mobile";
const CheckboxItem = Checkbox.CheckboxItem;
const tabs2 = [
	{ title: '未使用', sub: '1' },
	{ title: '已使用', sub: '2' },
	{ title: '过期', sub: '3' },
];
let initialPage = 0;

class Coupon extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			usedData: [],//已使用
			expireData: [],//过期
			type: '',//是否是相同活动
			modal: false,
			combileCode: '',
			combileMoney: ''
		};
	}
	componentWillMount() {
		this.props.getCouponByType({ type: 1 });
		this.props.getCouponByType({ type: 2 });
		this.props.getCouponByType({ type: 3 });
	}
	componentWillUpdate(nextProps, nextState) {
		//优惠券成功回调
		Util.fetchCallback({
			status: nextProps.Coupon.getCoupoByTypeStatus,
			code: nextProps.Coupon.getCoupoByTypeCode,
			message: nextProps.Coupon.getCoupoByTypeMessage,
			updateStatus: nextProps.updateGetCouponByTypeStatus,
			successCallback: () => {
				let newData = nextProps.Coupon.getCoupoByTypeData;
				if (nextProps.Coupon.type.type == 1) {
					if (newData.length) {
						this.setState({
							data: newData
						});
					} else {
						this.setState({
							data: []
						});
					}

				} else if (nextProps.Coupon.type.type == 2) {
					if (newData.length) {
						this.setState({
							usedData: newData
						});
					} else {
						this.setState({
							usedData: []
						});
					}
				} else if (nextProps.Coupon.type.type == 3) {
					if (newData.length) {
						this.setState({
							expireData: newData
						});
					} else {
						this.setState({
							expireData: []
						});
					}
				}

			}
		});

		//优惠券失败回调
		Util.fetchCallback({
			status: nextProps.Coupon.status,
			code: nextProps.Coupon.code,
			message: nextProps.Coupon.message,
			updateStatus: nextProps.changeCouponFailStatus,
			successCallback: () => { }
		});

		//合并扫码
		Util.fetchCallback({
			status: nextProps.Coupon.combileQrStatus,
			code: nextProps.Coupon.combileQrCode,
			message: nextProps.Coupon.combileQrMessage,
			updateStatus: nextProps.updateCombileQrStatus,
			successCallback: () => {
				console.log(nextProps.Coupon.combileQrMessage, 'uuuuuuuuu');
				let data = nextProps.Coupon.combileQrData;
				if (data && data.mergeCouponNo) {
					this.setState({
						modal: true,
						combileCode: data.mergeCouponNo,
						combileMoney: data.mergeCouponValue,
					})
				}

			}
		});
	}
	_use = item => {
		item.showQrCode = !item.showQrCode
		item.use = true
		this.forceUpdate()
	};
	onChange = (checkedValues) => {
		console.log(checkedValues, '99999999');
		// let type = checkedValues.split(',')[0];
		let id = checkedValues.split(',')[1];
		let newData;
		// if (type == this.state.type) {
		// 	newData = this.state.data && this.state.data.map((item, index) => {
		// 		if (item.id == id) {
		// 			item.checked = !item.checked;
		// 		}
		// 		return item;
		// 	});
		// } else {
		// 	newData = this.state.data && this.state.data.map((item, index) => {
		// 		if (item.activityNo == type) {
		// 			item.checked = true;
		// 		} else {
		// 			item.checked = false;
		// 		}
		// 		return item;
		// 	});
		// }
		newData = this.state.data && this.state.data.map((item, index) => {
			if (item.id == id) {
				item.checked = !item.checked;
			}
			return item;
		});

		this.setState({
			data: newData,
			// type
		})
	}

	combileQr = (arr) => {
		if (arr.length < 11) {
			this.props.combileQr(arr);
		} else {
			Toast.fail('一次最多合并十张优惠券');
		}

	}

	showBtn = () => {
		let arr = [];
		if (this.state.data) {
			this.state.data && this.state.data.map((item, index) => {
				if (item.checked) {
					arr.push(item.id);
				}
				return item;
			});
			if (arr.length) {
				return (
					<Button onClick={() => { this.combileQr(arr) }}>合并扫码</Button>
				)
			} else {
				return (
					<Button disabled>合并扫码</Button>
				)
			}

		} else {
			return '';
		}

	}


	onClose = () => {
		this.setState({
			modal: false
		})
	}

	notUseCoupon = () => {
		if (this.state.data != undefined) {
			return this.state.data.map((item, index) => {
				return (
					<li key={index}>
						<CheckboxItem checked={item.checked} key={item.activityNo + ',' + item.id} onChange={() => { this.onChange(item.activityNo + ',' + item.id) }}>
							<img src={ticket} alt="" />
							<div className="cut_price">
								￥<span>{item.balance}</span>
							</div>
							<div className="coupon_details">
								<div className="right">
									<div className="coupon_title">{item.activityTitle}</div>
									<div className="coupon_type3">
										优惠券 {item.couponNo.substring(0, 7) + '......' + item.couponNo.substring(item.couponNo.length - 7, item.couponNo.length)}
									</div>
									<div className="expiry_date">
										有效期:<span>{item.activityValidDate}</span>
									</div>
								</div>
							</div>
							<div className="get_coupon">
								<a className={item.use ? 'show-qrcode' : ''} onClick={() => this._use(item)}>使用</a>
								<div className="line"></div>
								<Link className="sells" to={"/couponQr/" + item.mtDataId + '?type=1'}>
									详情
              					</Link>
							</div>
							{item.showQrCode && <img
								className='img-qrcode'
								src={"/wx-web/public/web/getQrcode.do?content=" + item.couponNo}
								alt="qrCode"
							/>}
						</CheckboxItem>
					</li>
				);
			});
		}
	}

	usedCoupon = () => {
		if (this.state.usedData != undefined) {
			return this.state.usedData.map((item, index) => {
				return (
					<li key={index}>
						<img src={ticket} alt="" />
						<div className="cut_price dp-price">
							￥<span>{item.balance}</span>
						</div>
						<div className="coupon_details used-coupon-dp">
							<div className="right">
								<div className="coupon_title">{item.activityTitle}</div>
								<div className="coupon_type3">
									优惠券 {item.couponNo.substring(0, 7) + '......' + item.couponNo.substring(item.couponNo.length - 7, item.couponNo.length)}
								</div>
								<div className="expiry_date">
									有效期:<span>{item.activityValidDate}</span>
								</div>
							</div>
						</div>
						<div className="get_coupon">
							<Link className="sells" to={"/couponQr/" + item.mtDataId + '?type=1'}>
								详情
              					</Link>
						</div>
					</li>
				);
			});
		}
	}


	expireCoupon = () => {
		if (this.state.expireData != undefined) {
			return this.state.expireData.map((item, index) => {
				return (
					<li key={index}>
						<img src={ticket} alt="" />
						<div className="cut_price dp-price">
							￥<span>{item.balance}</span>
						</div>
						<div className="coupon_details used-coupon-dp">
							<div className="right">
								<div className="coupon_title">{item.activityTitle}</div>
								<div className="coupon_type3">
									优惠券 {item.couponNo.substring(0, 7) + '......' + item.couponNo.substring(item.couponNo.length - 7, item.couponNo.length)}
								</div>
								<div className="expiry_date">
									有效期:<span>{item.activityValidDate}</span>
								</div>
							</div>
						</div>
						<div className="get_coupon">
							<Link className="sells" to={"/couponQr/" + item.mtDataId + '?type=2'}>
								详情
              					</Link>
						</div>
					</li>
				);
			});
		}
	}

	onTabClick = (tab, index) => {
		initialPage = index;
	}


	render() {

		return (
			<div>
				<div className="title">
					优惠券
					<Link to="/exchangeCoupon" className="exchange-coupon">
						兑换优惠券
          			</Link>
				</div>
				<Tabs tabs={tabs2}
					initialPage={initialPage}
					onTabClick={this.onTabClick}
					renderTab={tab => <span>{tab.title}</span>}
				>
					<div>
						<div className="activity-data">
							{
								this.showBtn()
							}
							<ul className="data">{this.notUseCoupon()}</ul>
							<div
								className="no-data"
								style={{
									display:
										this.state.data == undefined || this.state.data.length == 0
											? "block"
											: "none"
								}}
							>
								<img src={coupon} alt="" />
								<p className="comming">一大波优惠券正在赶来~</p>
							</div>
						</div>
						<Modal
							visible={this.state.modal}
							transparent
							maskClosable={true}
							onClose={this.onClose}
							title="合并扫码"
							footer={[{ text: '关闭', onPress: () => { this.onClose() } }]}
						>
							<div className='combile-div'>
								<img
									src={"/wx-web/public/web/getQrcode.do?content=" + this.state.combileCode}
									alt="" />
								<p>{this.state.combileCode}</p>
								<p className='combile-money'>共用券面值{this.state.combileMoney}元</p>
							</div>
						</Modal>
					</div>
					<div>
						<div className="activity-data">
							<ul className="data">{this.usedCoupon()}</ul>
						</div>
					</div>
					<div>
						<div className="activity-data">
							<ul className="data">{this.expireCoupon()}</ul>
						</div>
					</div>
				</Tabs>

			</div>
		);
	}
}

export default connect(
	state => {
		return { Coupon: state.Coupon };
	},
	dispatch => bindActionCreators({ ...actions }, dispatch)
)(Coupon);

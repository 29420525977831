
import * as ALL_CONFIG from "../../../../config/index";
const ENV = process.env.NODE_ENV || "production";
const CONFIG = ALL_CONFIG[ENV];
import { info as infoStorage } from "@/util/storageManager";
import { Toast } from "antd-mobile";
import { getCookie } from "@/util";

class Remote {
    DEFAULT_OPTIONS = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    PROXY_KEY = "park"

    remote = (url, options = {}) => {
        const sessionToken = this.getSessionToken();
        this.DEFAULT_OPTIONS.headers.sessionToken = sessionToken;

        const { isNeedMemberPhone = false, showErrToast = true, ...resOptions } = options
        const { mobile: memberPhone = undefined } = infoStorage.getItem() || {};

        if (isNeedMemberPhone) {
            if (!memberPhone) {
                window.location.href = "/";
                return;
            }
            if (options.method === "POST" && resOptions.body) {
                resOptions.body = { ...resOptions.body, memberPhone }
            }
            if (options.method === "GET") {
                url = `${url}&memberPhone=${memberPhone}`
            }
        }

        if (resOptions.body) {
            resOptions.body = JSON.stringify(resOptions.body)
        }

        const { context } = CONFIG.proxyInfo[this.PROXY_KEY];
        const finalUrl = `${ALL_CONFIG.getParkDomainConfig()}${context}${url}`;

        return fetch(finalUrl, { ...this.DEFAULT_OPTIONS, ...resOptions })
            .then(response => {
                return new Promise((resolve, reject) => {
                    response.json().then(res => {
                        const { code, msg, message, result } = res
                        switch (code) {
                            case '0':
                            case 0:
                                resolve(res);
                                break;
                            case -1:
                                Toast.fail(msg, 1, () => {
                                    window.location.href = "/?redirectUrl=/park"
                                })
                                break;
                            default:
                                if (showErrToast) Toast.fail(msg)
                                reject({
                                    error: -100,
                                    code: code,
                                    message: msg || message,
                                    result,
                                });
                        }
                    })
                });
            })
    }

    get = (url, urlParams = undefined) => {
        const { isNeedMemberPhone, showErrToast, ...resUrlParams } = urlParams
        const transUrl = this.genQuery(resUrlParams)
        return this.remote(`${url}${transUrl}`, { method: "GET", isNeedMemberPhone, showErrToast })
    }

    post = (url, options = {}) => {
        return this.remote(url, { ...options, method: "POST" })
    }

    genQuery = (queryData) => {
        if (!queryData) return '';
        let ret = '';
        Object.keys(queryData).forEach(key => {
            const val = queryData[key]
            if (typeof val !== 'undefined') {
                ret += `&${key}=${encodeURIComponent(val)}`;
            }
        })
        return ret.replace(/&/, '?');
    };

    getSessionToken = () => {
        let token = getCookie("sessionToken");
        let dpToken = localStorage.getItem("sessionToken");
        return token || dpToken
    }
}

const remote = new Remote();
export default {
    get: remote.get,
    post: remote.post,
    getSessionToken: remote.getSessionToken
};


import React from "react";
import Proptypes from "prop-types";
import { Icon } from "antd-mobile";
// import headBg from './img/head-bg.png';
import "./index.less";
import classNames from "@/util/classNames";
const prefix = "park-head";
const cx = classNames(prefix);

const Head = ({ title, iconType, handleProps }) => {
  return (
    <div className={prefix}>
      <div className={cx("center")} {...handleProps}>
        <div className={cx("title")}>{title}</div>
        { title && <div className={cx("icon")}></div> }
      </div>
    </div>
  );
};

Head.propTypes = {
  title: Proptypes.string,
  iconType: Proptypes.string,
  handleProps: Proptypes.object,
};

Head.defaultProps = {
  handleProps: {},
};

export default Head;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { WingBlank, Toast, Picker, List} from 'antd-mobile';
import * as Util from '../../util/index';
import * as actions from './index.action';
import './index.less';
import iphone from './images/iphone.png';

class WxAdd extends Component {
    constructor(props){
        super(props);
        const mobile=Util.getUrlArg('mobile');
        const openid=Util.getUrlArg('openid');
        const originId = Util.getUrlArg('originId');
        const employeeName = Util.getUrlArg('employeeName');
        this.state={
            isShow: (mobile && originId)  ? true : false,
            showName: false,
            mobile,
            openid,
            originId,
            employeeName,
            companyData: [],
        }
    }
    
    componentWillUpdate (nextProps, nextState) {
        //获取枚举
        Util.fetchCallback({
            status: nextProps.wxAdd.getCompanyStatus,
            code: nextProps.wxAdd.getCompanyCode,
            message: nextProps.wxAdd.getCompanyMessage,
            updateStatus: nextProps.updateGetCompanyEnumStatus,
            successCallback: () => {
                let myData = nextProps.wxAdd.getCompanyData;
                let newData = [];
                let obj = {};
                myData.map((item) => {
                    obj = item;
                    obj['label'] = item['originName'];  
                    obj['value'] = item['originId'];
                    delete obj['originName'];
                    delete obj['originId'];
                    newData.push(obj)
                });
                this.setState({companyData:myData});
            }
        });
		// add
		Util.fetchCallback({
            status: nextProps.wxAdd.bindPhoneStatus,
            code: nextProps.wxAdd.bindPhoneCode,
            message: nextProps.wxAdd.bindPhoneMessage,
            updateStatus: nextProps.updateGetResStatus,
            successCallback: () => {
				Toast.show(nextProps.wxAdd.bindPhoneMessage);
                this.setState({isShow:true});
            }
        });
    }
    componentDidMount(){
        this.props.getCompanyEnum();
        const originId = this.state.originId;
        const mobile = this.state.mobile;
        const openid = this.state.openid;
        const employeeName = Util.getUrlArg('employeeName');
        if(mobile && originId && openid){
            this.props.bindPhone({
                mobile,
                openid,
                originId:originId.toString(),
                employeeName,
            })
        }
    }

    handleCompanyChange = (v) => {
        const val = v.toString()
        if(val != 1){
            this.setState({
                showName:true
            });
        }else{
            this.setState({
                showName: false
            });
        }
        this.setState({
            originId: v,
        })
    }

    handleCompanyOk = (v) => {
        const val = v.toString()
        if(val != 1){
            this.setState({
                showName: true
            });
        }else{
            this.setState({
                showName: false
            });
        }
        this.setState({
            originId: v,
        })
    }

    handleMobileChange = (e) => {
        const val = e.target.value;
        this.setState({
            mobile: val,
        })
    }

    handleManChange = (e) => {
        const val = e.target.value;
        this.setState({
            employeeName: val,
        })
    }

    bindPhone = () => {
        const originId = this.state.originId;
        const mobile = this.state.mobile;
        const openid = this.state.openid;
        const employeeName = this.state.employeeName;
        if(!originId){
            Toast.show('请选择公司名！');
            return;
        }
        if(!mobile){
            Toast.show('请填写手机号！');
            return;
        }
        if(originId.toString() != 1 && employeeName == '' ){
            Toast.show('请填写姓名！');
            return;
        }
        this.props.bindPhone({
            mobile,
            openid,
            originId:originId.toString(),
            employeeName,
        })
    }

    goEdit = () => {
        this.props.history.push(`/wxedit?oldMobile=${this.state.mobile}&openid=${this.state.openid}`)
    }
    render() {
		return (
			<div>
			  <div className="login-logo">
				 <img src="" alt=""/>
			  </div>
              {
                  this.state.isShow
                  ? <WingBlank>
                        <div className='dp-input-div'>
                            <p style={{textAlign: 'center', fontSize: 24}}>提交成功</p>
                        </div>
                        <div className='dp-input-div'>
                            <button className="login-btn" onClick={this.goEdit}>修改信息</button>
                        </div>  
                    </WingBlank>
                  : <WingBlank>
                        <Picker
                            data={this.state.companyData}
                            cols={1}
                            className="forss"
                            onChange={this.handleCompanyChange}
                            onOk={this.handleCompanyOk}
                            value={this.state.originId}
                        >
                            <List.Item arrow="horizontal">公司名字</List.Item>
                        </Picker>
                        {
                            this.state.showName == true
                                ? <div className="input-div dp-div">
                                    <img src={iphone} alt=""/>
                                    <input type="text" placeholder="姓名" value={this.state.employeeName} onChange={this.handleManChange}/>
                                </div>
                                : null
                        }
                        <div className="input-div dp-div">
                            <img src={iphone} alt=""/>
                            <input type="phone" placeholder="绑定手机号" value={this.state.mobile} onChange={this.handleMobileChange}/>
                        </div>
                        <div className='dp-input-div'>
                            <button className="login-btn" onClick={this.bindPhone}>绑定</button>
                        </div>				  
                    </WingBlank>
              }
              </div>
              )
              }
}

export default connect(
	(state) => {
		return {wxAdd: state.wxAdd,FetchState: state.Fetch}
	},
	(dispatch) => bindActionCreators({...actions}, dispatch)
)(WxAdd);
import React from "react";

import { Toast } from "antd-mobile";

import "./login.less";

import request from "../request";

import GetVerifyCodeBtnHoc from "../GetVerifyCodeBtnHoc";

const GetVerifyCodeBtn = GetVerifyCodeBtnHoc(
  ({ count, status, updateStatus, mobile, imgCode }) => {
    const send = async () => {
      if (mobile.trim() === "") {
        Toast.fail("请输入手机号");
        return;
      }

      if (imgCode.trim() === "") {
        Toast.fail("请输入图形验证码");
        return;
      }
      const url = "/wx-web/public/web/getVerifyCode.do";
      const options = {
        method: "POST",
        body: JSON.stringify({
          data: { mobile: mobile.trim(), imgCode: imgCode.trim() },
        }),
      };
      updateStatus(1);
      const json = await request(url, options);

      const { msg = "发送验证码失败" } = json || {};

      Toast.info(msg, 2, null, false);

      updateStatus(2);
    };
    const handle = () => {
      if (status === 0 || status === 3) {
        send();
      }
    };

    const Text = () => {
      if (status === 0) {
        return "获取验证码";
      }

      if (status === 1) {
        return "发送中...";
      }

      if (status === 2) {
        return `${count}s后重新发送`;
      }

      if (status === 3) {
        return "重新发送";
      }
    };
    const classes = status === 1 || status === 2 ? "get-code gray" : "get-code";
    return (
      <div className={classes} onClick={handle}>
        <Text />
      </div>
    );
  }
);

export default GetVerifyCodeBtn;

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Icon, Toast, Modal, Button } from 'antd-mobile';
import { Head, NewModalCustomize, AppBar } from '../components';

import classNames from '@/util/classNames';
import delay from '@/util/delay';
import { parkCurCar as parkCurCarStorage } from '@/util/storageManager';
import { ReloadOutlined } from './icon';

import './index.less';
const prefix = 'park-welcome';
const cx = classNames(prefix);

const PAY_URL = ({ parkId, carNumber }) => {
    const result = {
        9104: `http://qqb.welinkpark.com/cw/g.html?b=t&s=${parkId}-${carNumber}`, // 上海金桥店
        1004: `https://cloud.keytop.cn/stcfront/Parking/Query?lpn=${carNumber}`, // 上海龙阳店
        1801: `http://app.gateares.com:19983/cc-pay/index.html?commId=1.000.729&plateNO=${encodeURIComponent(
            carNumber
        )}`, // 广州天河店正式地址
        kt: `https://cloud.keytop.cn/stcfront/Parking/Query?lotId=${parkId}&lpn=${carNumber}`, // 自营店
        typeZero: `http://wx.ymiot.net/SCPay/?carNo=${carNumber}`,
    };
    return result;
};

function debounce(fn, wait) {
    let task = undefined;
    return (...arg) => {
        if (task) {
            clearTimeout(task);
            task = undefined;
        }
        task = setTimeout(() => fn(...arg), wait);
    };
}

class Welcome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carList: [],
            curCar: {},
            sureErrorPayModal: false, // 无支付信息时确认弹框
            sureErrorContent: '',
            carFeePreViewData: {},
            preViewPayModal: '',
        };
    }

    fetchCarList = async () => {
        const json = await this.props.getCarList();
        const { result: { data: carList = [] } = {} } = json || {};
        if (carList.length === 0) {
            Toast.info('您还没绑定车辆, 请先绑定', 1, () => {
                this.props.history.replace('/park/add', { empty: true });
            });
            return;
        }
        this.setState({ carList });
        return carList;
    };

    getCurCar = (list) => {
        const curCar = parkCurCarStorage.getItem();
        let result = {};
        if (curCar) {
            const { id: checkId } = curCar;
            const findCarById = list.filter(({ id }) => checkId === id)[0];
            if (findCarById) {
                result = findCarById; // 使用findCarById, 在修改后可以替换新的
            } else {
                defaultCurCar();
            }
        } else {
            defaultCurCar();
        }

        function defaultCurCar() {
            result = list[0] || {};
            parkCurCarStorage.setItem(result);
        }
        this.setState({ curCar: result || {} });
    };

    componentDidMount = async () => {
        document.body.scrollTo(0, 0);
        this.props.autoPosition(); // qq  从缓存获取到当前门店及当前城市门店列表 || 没有缓存调后台定位的接口
        this.fetchCarList(); // qq  获取车牌号列表
        this.props.getKtCarShopList();
    };

    componentDidUpdate = async (prevProps, prevState) => {
        const {
            parkNew: {
                selected: { shopNo: prevShopNo },
            },
        } = prevProps;
        const {
            parkNew: {
                selected: { shopNo },
            },
        } = this.props;
        const {
            carList,
            curCar: { carNumber },
        } = this.state;
        const {
            carList: prevCarList,
            curCar: { carNumber: prevCarNumber },
        } = prevState;

        if (carList.length !== prevCarList.length) {
            //列表有变动 会设置当前选择车辆;修改车辆 会跳转页面  所以这里不用处理 只监听删除
            this.getCurCar(carList);
        }

        if (carNumber != prevCarNumber || shopNo != prevShopNo) {
            if (carNumber === undefined || shopNo === undefined) return; // 存在以下2个参数 否则 break
        }
    };

    goThirdpay = async () => {
        try {
            const {
                parkNew: {
                    selected: { parkId, shopNo },
                    ktCarShopListAll,
                },
                getCarFeeRecord,
            } = this.props;
            const {
                curCar: { carNumber },
            } = this.state;

            let isTypeZero = false;
            if (shopNo !== '1801' && ktCarShopListAll.includes(shopNo)) {
                const json = await getCarFeeRecord({ shopNo, carNumber });
                const { result: { data: info = {} } = {} } = json;
                isTypeZero = info && info.type == 0;
            }

            const isMiniprogram = window.__wxjs_environment === 'miniprogram';
            if (isMiniprogram && ktCarShopListAll.includes(shopNo)) {
                this.handleMiniProgram(parkId, carNumber);
                return;
            }

            const payKey = isTypeZero
                ? 'typeZero'
                : ktCarShopListAll.includes(shopNo)
                ? 'kt'
                : shopNo;
            window.location.href = PAY_URL({
                parkId,
                carNumber,
            })[payKey];
        } catch (error) {
            const { message } = error;
            this.setState({
                sureErrorPayModal: true,
                sureErrorContent: message,
            });
        }
    };

    handlePay = async () => {
        try {
            // 获取到停车记录 验证车是否进场
            const {
                parkNew: {
                    selected: { shopNo },
                    ktCarShopListSelf,
                },
            } = this.props;
            const {
                curCar: { carNumber },
            } = this.state;

            if (ktCarShopListSelf.includes(shopNo)) {
                // 如果是自营+科拓   支付前弹框显示停车信息
                const { data = {} } = await this.props.carFeePreView({ carNumber });
                this.setState({
                    preViewPayModal: true,
                    carFeePreViewData: data,
                });
            } else {
                // 除了自营+科拓  直接跳转到第三方支付链接
                this.goThirdpay();
            }
        } catch (error) {
            // 无车辆记录时  提示错误信息
            const { message } = error;
            this.setState({
                sureErrorPayModal: true,
                sureErrorContent: message,
            });
        }
    };

    goEdit = () => {
        const curCar = parkCurCarStorage.getItem();
        let result = {};
        if (curCar) {
            const { id: checkId } = curCar;
            this.props.history.push(`/park/edit/${curCar.id}`);
        }
    }

    handleMiniProgram = (parkId, carNumber) => {
        wx.miniProgram.postMessage({
            data: {
                type: 'parkJumpThird',
                appId: 'wx54e676273869baa6',
                path: `pages/index/index?lotId=${parkId}&lpn=${carNumber}`,
            },
        });
        wx.miniProgram.navigateBack();
    };

    render() {
        const {
            parkNew: {
                selected: { shopName = '', parkId, shopNo },
            },
            carListIsLoading,
            carFeeLoading,
            autoPositionLoading,
            carFeePreViewLoading,
            ktCarShopListLoading,
        } = this.props;

        const {
            carList,
            curCar,
            sureErrorPayModal,
            sureErrorContent,
            preViewPayModal,
            carFeePreViewData,
        } = this.state;

        const CarInfo = () => {
            return (
                <div className={cx('car-number-wrap')}>
                    <p className='parkcommon-tip-title'>车牌</p>
                    <div className={cx('car-number')}>
                        <div className={cx('car-number-center')} >
                            <div className={cx('car-number-text')} onClick={this.goEdit}>
                                {curCar.carNumber}
                                <div className={cx('car-number-edit')}></div>
                                <span>绑定</span>
                            </div>
                            <Link className={cx('car-number-otherpay')} to='/park/payother'>
                                帮其他车牌缴费
                            </Link>
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <div className={cx('wrap')}>
                <AppBar
                    link={null}
                    title='停车服务'
                />
                <Head
                    title={autoPositionLoading ? '加载中...' : `${shopName}`}
                    iconType='down'
                    handleProps={{
                        onClick: () => {
                            if (autoPositionLoading) return;
                            this.props.history.push('/park/shop');
                        },
                    }}
                />
                {!(carListIsLoading && ktCarShopListLoading) ? (
                    <div>
                        <CarInfo />
                        <div className={cx('pay-btn-wrap')}>
                            <Button
                                className='parkcommon-am-button'
                                style={{ marginBottom: '24px'}}
                                onClick={() => this.handlePay()}
                                loading={carFeeLoading || carFeePreViewLoading}
                                disabled={carFeeLoading || carFeePreViewLoading}
                            >
                                查询缴费
                            </Button>
                            <Link to='/park/record' className={cx('record')}>
                                缴费记录
                            </Link>
                        </div>
                    </div>
                ) : (
                    <div className={cx('loading-wrap')}>
                        <Icon type='loading' size='md' />
                        <p>加载中...</p>
                    </div>
                )}

                {/* <div className={cx("fresh-mask")} /> */}
                <NewModalCustomize
                    visible={sureErrorPayModal}
                    transparent
                    content={
                        <p>
                            <span>{sureErrorContent}</span>
                        </p>
                    }
                    footer={[
                        {
                            text: '知道了',
                            onPress: (e) => {
                                if (e) e.preventDefault();
                                this.setState({ sureErrorPayModal: false });
                            },
                        },
                    ]}
                />

                {/* 查缴停车费之前的预览 */}
                <NewModalCustomize
                    visible={preViewPayModal}
                    transparent
                    title={`【${curCar.carNumber}】`}
                    content={
                        <div className='preViewPayContent'>
                            <p>
                                <span>入场时间：</span>
                                <span>{carFeePreViewData.entryTime}</span>
                            </p>
                            <p>
                                <span>停车时长：</span>
                                <span>{carFeePreViewData.parkTime}</span>
                            </p>
                            <p>
                                <span>优惠时长：</span>
                                <span>{carFeePreViewData.deductTime}</span>
                            </p>
                        </div>
                    }
                    footer={[
                        {
                            text: '取消',
                            onPress: (e) => {
                                if (e) e.preventDefault();
                                this.setState({ preViewPayModal: false });
                            },
                        },
                        {
                            text: '去支付',
                            onPress: (e) => {
                                if (e) e.preventDefault();
                                this.setState({ preViewPayModal: false });
                                this.goThirdpay();
                            },
                        },
                    ]}
                />
            </div>
        );
    }
}

const key = 'parkNew';
const ConnectedWelcome = connect(
    (state) => {
        return {
            parkNew: state.parkNew,
            carListIsLoading: state.loading.effects[key].carList,
            carFeeLoading: state.loading.effects[key].carFeeRecord,
            autoPositionLoading: state.loading.effects[key].autoPosition,
            carFeePreViewLoading: state.loading.effects[key].carFeePreView,
            ktCarShopListLoading: state.loading.effects[key].getKtCarShopList,
        };
    },
    (dispatch) => {
        return {
            autoPosition: dispatch[key].autoPosition,
            getCarList: dispatch[key].carList,
            getCarFeeRecord: dispatch[key].carFeeRecord,
            carFeePreView: dispatch[key].carFeePreView,
            getKtCarShopList: dispatch[key].getKtCarShopList,
        };
    }
)(Welcome);

export default ConnectedWelcome;

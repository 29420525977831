import React from "react";
import Proptypes from "prop-types";
import { Icon } from "antd-mobile";
import oneName from "./one";
import twoAlias from "./two";

import "./Keyboard.less";
import classNames from "@/util/classNames";
const prefix = "park-car-input-keyboard-keyboard";
const cx = classNames(prefix);

const one = oneName.map(({ name }) => name);

const two = twoAlias.map((item) => {
  if (/[a-zA-Z]/.test(item)) {
    return item.toUpperCase();
  }

  return item;
});
const LAYOUT = {
  one,
  two,
};

const DISABLEDS = ["i", "o"];

const getIsDisabled = (target) => {
  const filter = DISABLEDS.filter((item) => {
    const regex = new RegExp(item, "i");
    return regex.test(target);
  });

  return filter.length > 0;
};

export default class Keyboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "one",
    };
  }

  handleChange = (item) => {
    const { onChange, value } = this.props;
    const numberPlate = `${value}${item}`
    // 限制车牌长度
    if (numberPlate.length > 8) {
      const intercept = numberPlate.substr(0, numberPlate.length - 2)
      onChange(`${intercept}${item}`);
      return;
    }
    onChange(numberPlate);
  };

  handleDel = () => {
    const { onChange, value } = this.props;
    const len = value.length;
    onChange(value.slice(0, len - 1));
  };

  componentDidUpdate = (prevProps) => {
    const { value } = this.props;
    const { value: prevValue } = prevProps;

    if (value !== prevValue) {
      if (value === "") {
        this.setState({ type: "one" });
      } else {
        this.setState({ type: "two" });
      }
    }
  };

  render() {
    const { type } = this.state;
    return (
      <div className={prefix}>
        <div className={cx("list")}>
          {LAYOUT[type].map((item) => (
            <button
              disabled={getIsDisabled(item)}
              key={item}
              className={cx("item")}
              onClick={() => this.handleChange(item)}
            >
              {item}
            </button>
          ))}
          <div className={cx("item", "del")} onClick={this.handleDel}>
            删除
          </div>
        </div>
      </div>
    );
  }
}

export let getExchangeCoupon = (argus) => {
	return {
		type: 'GET_EXCHANGE_COUPON',
		playload: {
			url: '/web/changeCouponNo',
			type: 'get',
			param: {
				...argus
			}
		}
	}
}

export let updateGetExchangeCouponStatus = () => {
	return {
		type: 'UPDATE_GET_EXCHANGE_COUPON_STATUS'
	}
}
import { Toast } from 'antd-mobile';
import {
    getEnableShopAPI,
    getNearestShopAPI,
    getCityShopAPI,
    carListAPI,
    carFeeRecordAPI,
    carFeePreViewAPI,
    KtCarShopListAPI,
} from './welcomApi';

import { carBindAPI, carDeleteAPI, carUpdateAPI, carDetailByIdAPI } from './addEditApi';

import { historyListAPI, historyDetailAPI, historyParksAPI } from './recordApi';
import * as Util from '@/util/index';
import { parkCurShop as parkCurShopStorage } from '@/util/storageManager';
import getCurrentPosition from './getCurrentPosition';

export default {
    state: {
        list: [],
        isLoading: false,
        error: false,
        selected: {},
        ktCarShopListAll: [],
        ktCarShopListSelf: [],
    },
    reducers: {
        saveShopList(state, list) {
            // save 店铺列表
            return { ...state, isLoading: false, list };
        },
        saveSelectedShop(state, selected) {
            // save  被选中的店铺
            return { ...state, selected };
        },
        errorShop(state, error) {
            return { ...state, isLoading: false, error };
        },
        saveKtCarShopList(state, data) {
            return {
                ...state,
                ...data,
            };
        },
    },
    effects: {
        // 进入Welcome 自动定位
        async autoPosition() {
            const storage = parkCurShopStorage.getItem();
            if (storage) {
                //有储存的curShop=>根据curShop里面的city 获取shopList;
                this.saveSelectedShop(storage);
                const { city } = storage;
                await this.getCityShop({ city });
            } else {
                // 无curShop=>获取经纬度 拿到city 请求shopList
                await this.startPosition('auto');
            }
        },

        // shop页面 手动定位
        async manualPosition() {
            Toast.loading('定位中...', 0);
            await this.startPosition('manual');
        },

        // 获取店铺列表
        async getCityShop({ city }) {
            try {
                const json = await getCityShopAPI({ city });
                const { result: { data = [] } = {} } = json;
                this.saveShopList(data);
                const shopNo = Util.getUrlArg('shopNo') || '';
                if(shopNo){
                    const shopData = data.find((i) => {
                        return i.shopNo === shopNo
                    });
                    if(shopData){
                        this.saveSelectedShop(shopData);
                        parkCurShopStorage.setItem(shopData); // 存储
                        return;
                    }
                }
                const storage = parkCurShopStorage.getItem();
                if (!storage || !city) {
                    // 获取shopList 如果没有储存curShop  存列表里面的第一个shop
                    const [first] = data;
                    this.saveSelectedShop(first);
                    parkCurShopStorage.setItem(first); // 存储
                }
            } catch (error) {
                Toast.fail(error.message);
            }
        },

        // 获取经纬度
        async startPosition(type) {
            console.log("type", type);
            try {
                // const position = await getCurrentPosition();
                // const { coords: { latitude, longitude } = {} } = position || {};
                const latitude = 30.27415;
                const longitude = 120.15515;
                // 请求最近的门店
                const json = await getNearestShopAPI({ latitude, longitude });
                const { result: { data: { cityCode: city } = {} } = {} } = json;
                // 获取shopList
                await this.getCityShop({ city });
                if (type === "manual") Toast.hide();

            } catch (error) {
                await this.getCityShop({ city: undefined });
                if (type === "manual") {
                    const { message } = error;
                    Toast.hide();
                    Toast.fail(`定位失败:${message} 建议你手动切换到正确的门店！`);
                }
            }
            // 根据经纬度定位获取shopList
            // try {
            // TODO 定位逻辑去除
            // const position = await getCurrentPosition();
            // const { coords: { latitude, longitude } = {} } = position || {};
            // // 请求最近的门店
            // const json = await getNearestShopAPI({ latitude, longitude });
            // const { result: { data: { cityCode: city } = {} } = {} } = json;
            // 获取shopList
            // await this.getCityShop({ city: undefined });
            // if (type === 'manual') Toast.hide();

            // } catch (error) {
            //     await this.getCityShop({ city: undefined });
            //     if (type === "manual") {
            //         const { message } = error;
            //         Toast.hide();
            //         Toast.fail(`定位失败:${message} 建议你手动切换到正确的门店！`);
            //     }
            // }
        },

        // 绑定的车辆
        async carList() {
            const json = await carListAPI();
            return json;
        },

        // 支付信息
        async carFeeRecord({ shopNo, carNumber }) {
            return await carFeeRecordAPI({ shopNo, carNumber });
        },

        // 停车信息预览
        async carFeePreView({ shopNo, carNumber }) {
            return await carFeePreViewAPI({ shopNo, carNumber });
        },

        // 绑定车辆
        async carBind(body) {
            return await carBindAPI(body);
        },

        // 删除车辆
        async carDel(body) {
            return await carDeleteAPI(body);
        },

        // 车辆详情
        async carDetailById(body) {
            return await carDetailByIdAPI(body);
        },

        // 更新车辆
        async carUpdate(body) {
            return await carUpdateAPI(body);
        },

        // 缴费记录
        async historyList(body) {
            return await historyListAPI(body);
        },

        // 缴费详情
        async historyDetail(body) {
            return await historyDetailAPI(body);
        },

        // 历史停车场
        async getHistoryParks(body) {
            return await historyParksAPI(body);
        },

        // 获取需要跳转到科拓的店铺
        async getKtCarShopList() {
            try {
                const { data = [] } = await KtCarShopListAPI();
                const ktCarShopListAll = data.map((i) => i.shopCode);
                const ktCarShopListSelf = data.filter((i) => i.self == 1).map((i) => i.shopCode);
                this.saveKtCarShopList({ ktCarShopListAll, ktCarShopListSelf });
            } catch (error) {
                Toast.fail(error.message);
            }
        },
    },
};

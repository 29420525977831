export let queryInfo = () => {
	return {
		type: 'QUERY_INFO',		
		playload: {
			url: '/web/queryInfo.do',
			type: 'get'
		}
	}
}


export let updateStatus = ()=>{
	return {
		type:'UPDATE_LOGIN_STATUS'
	}
}

export let getIndexImg = () => {
	return {
		type: 'GET_INDEX_IMG',		
		playload: {
			url: '/public/banner/getIndexImg',
			type: 'get',
			isService:true
		}
	}
}


export let updateGetIndexImgStatus = ()=>{
	return {
		type:'UPDATE_GET_INDEX_IMG_STATUS'
	}
}


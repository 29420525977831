export let getCouponInfo = (argus) => {
	return {
		type: 'GET_COUPON_INFO',		
		playload: {
			url: '/web/getCouponInfo.do',
			type: 'get',
			param:{
				...argus
			}
		}
	}
}
import React, { Component } from "react";
import Proptypes from "prop-types";

import "./rule.less";

import Wrap from "./Wrap";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    visible: Proptypes.bool,
    handleModal: Proptypes.func,
  };

  render() {
    const { size } = this.state;
    const { ...wrapProps } = this.props;

    return (
      <Wrap {...wrapProps}>
        <div className="rule">
          活动细则：
          1、2020年5月15日至5月24日，顾客关注微信公众号“百安居会员之家”点击“我要抽奖”或扫码进入幸运转盘，注册/登陆百安居会员，即可参与抽奖，100%中奖，数量有限，领完即止。
          奖项设置如下： 特等奖：戴森空气净化器1个【共30名】
          一等奖：飞利浦西式室内电炙烤炉 1台【共100名】 二等奖
          摩飞便携果汁机MR9600 1台【共300名】 三等奖：飞利浦电吹风HP822705
          1个【共1000名】 参与奖：面值50元百安居购物礼金立减码1张（满388元使用）
          2、活动期间内，同一会员账户每天可有2次抽奖机会，但整个活动期间同一会员账户仅可领取一次奖品。会员抽中奖项后可选择“放弃，明天再来”或“领取奖品”，领取后该会员则不得再次参与抽奖。如会员选择了“领取奖品”，则奖项将以兑换凭证的形式自动存入该会员账户卡包中。
          3、除购物礼金立减码外，其余奖品兑换有效期：2020年5月15日-2020年5月24日。会员于前述兑换期间内到店凭本人会员卡以及会员账户中的奖品兑换凭证至百安居各门店服务台，向门店工作人员出示并进行核销
          , 同时确认收货地址及联系方式，奖品以快递形式
          寄出至顾客指定地址，若奖品无质量问题，不予退/换货
          4、购物礼金立减码使用有效期：2020年5月15日-2020年5月24日，会员凭会员卡进店消费，结账时在收银台出示立减码，消费满388元即可立减50元购物金额。使用购物礼金立减码的交易如发生退款，仅可退会员实付金额（不包含优惠金额），购物礼金立减码不予退回，且已享优惠资格不再补偿。购物礼金立减码全场通用，可与百安居商场同期促销活动（如有）同享，但单张小票不可同时使用多个礼金立减码。
          5、奖品兑换凭证和购物礼金立减码均不可出售和转让，不可兑换现金，不找零，过期作废。
          6、奖品若为顾客通过机器作弊等不正当行为获得，经百安居查证属实，百安居有权禁止其参与活动，取消其奖品兑换资格。
          7、若因中奖会员提供的信息不正确而导致的奖品快递问题，相关责任及后果需由会员自行承担，百安居将不作任何形式补偿或承担其他义务。
          8、新疆、西藏、海南、港澳台及中国境外会员可以参与本次活动，但奖品的发放需获奖者提供前述区域以外的收货地址和联系人，百安居不负责新疆、西藏、海南、港澳台及中国境外的快递服务。9、详询百安居店内工作人员或致电400-820-0707咨询。
        </div>
      </Wrap>
    );
  }
}

export default Modal;

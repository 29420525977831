let initState = {
	
}
export default function(state=initState, action) {
	switch(action.type) {
		case 'GETOPENID':
			return {
				...state,
				authStatus: true,
				authData: action.playload.data,
				authCode: action.playload.code,
				authMessage: action.playload.message
			}
			break;
		case 'GETOPENID_FAIL':
			return {
				...state,
				authStatus: true,
				authCode: action.playload.code,
				authMessage: action.playload.message
			}	
			break;
		case 'UPDATE_GETOPENID':
			return {
				...state,
				authStatus: false
			}
			break;		
		default:
			return state;
	}
}
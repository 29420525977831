import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './index.action';
import { Carousel } from 'antd-mobile';
import img1 from './images/1.jpg';
import img2 from './images/2.jpg';
import img3 from './images/3.jpg';
import img4 from './images/4.jpg';
import img5 from './images/5.jpg';
import img6 from './images/6.jpg';
import img7 from './images/7.jpg';
import * as Util from '../../util/index';

import './index.less';
const posterSrc=[img1,img2,img3,img4,img5,img6,img7];

class Poster extends Component {
	constructor(props) {
		super(props);

		this.state = {
			posterSrc:[]
		}
	}

	componentWillMount(){
		this.props.getCarousel();
		
	}

	posterImgFunc=()=>{

		if(!this.state.posterSrc.length){
			return (
				<Carousel>
					{
						posterSrc.map((item,i)=>{
							return <div key={i} className="poster-container"><img src={item} alt=""/></div>
						})
					}
				</Carousel>
			)
			
		}else if(this.state.posterSrc.length === 1){
			return (
				<div className="poster-container"><img src={this.state.posterSrc[0]} alt=""/></div>
			)
		}else if(this.state.posterSrc.length > 1){

			return (
				<Carousel>
					{
						this.state.posterSrc.map((item,i)=>{
							return <div key={i} className="poster-container"><img src={item} alt=""/></div>
						})
					}
				</Carousel>
			)
			 
		}

	}

	componentWillUpdate (nextProps, nextState) {
    
        Util.fetchCallback({
          status: nextProps.Poster.posterStatus,
          code: nextProps.Poster.posterCode,
          message: nextProps.Poster.posterMessage,
          updateStatus: nextProps.updateGetCarouselStatus,
          successCallback: () => {
			  
			 let data = nextProps.Poster.posterData;
			 
			 if(data.length){
				data.sort(function(a,b){
					return a.orders - b.orders
				})
				let arr = [];
				for(let value of data){
				   arr.push(value.urlImg)
				}
				this.setState({
				   posterSrc:arr
			   })
			 }
          }
          });
      }

	render() {
		
		return (
			<div className="post-div">
			{this.posterImgFunc()}
			</div>
		);
	}
}

export default connect(
	(state) => {
		return {Poster: state.Poster}
	},
	(dispatch) => bindActionCreators({...actions}, dispatch)
)(Poster);
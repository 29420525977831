export default [
  {
    name: "京",
  },
  {
    name: "津",
  },
  {
    name: "冀",
  },
  {
    name: "晋",
  },
  {
    name: "蒙",
  },
  {
    name: "辽",
  },
  {
    name: "吉",
  },
  {
    name: "黑",
  },
  {
    name: "沪",
  },
  {
    name: "苏",
  },
  {
    name: "浙",
  },
  {
    name: "皖",
  },
  {
    name: "闽",
  },
  {
    name: "赣",
  },
  {
    name: "鲁",
  },
  {
    name: "豫",
  },
  {
    name: "鄂",
  },
  {
    name: "湘",
  },
  {
    name: "粤",
  },
  {
    name: "桂",
  },
  {
    name: "琼",
  },
  {
    name: "渝",
  },
  {
    name: "川",
  },
  {
    name: "贵",
  },
  {
    name: "云",
  },
  {
    name: "藏",
  },
  {
    name: "陕",
  },
  {
    name: "甘",
  },
  {
    name: "青",
  },
  {
    name: "宁",
  },
  {
    name: "新",
  },
];

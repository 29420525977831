import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect ,Link} from 'react-router-dom';
import * as actions from './index.action';
import * as Util from '../../util/index';
import Toast from '../../components/prompt/toast';
import './index.less';
import carBg from './images/vip_card_a.png';
import carBg2 from './images/vip_card_b.png';
import clickShow from './images/click_show.png';
import act from './images/act.png';
import act2 from './images/banner.jpg';
import Bar from '../../components/bar/index';
import tab1 from './images/tab_poster.png';
import tab2 from './images/tab_info.png';
import tab3 from './images/tab_vip.png';
import cardBag from './images/addcard.png';
import {Modal,WingBlank ,Carousel } from 'antd-mobile';
import CryptoJS  from "crypto-js";


class Home extends Component {
	constructor(props) {
		super(props);
		this.state={
			isShow:true,
			mobile:"",
			ecardCode:"",
			registerTimeStr:"",
			customerLevelName:"",
			usablePoints:"",
			growth:"",
			data:{},
			ruleShow:false,
			urlMobile:'',
			indexImgArr:[] //banner图
		}
	}
	changeShow=()=>{
		this.setState({isShow:!this.state.isShow});
	}


	rulesNotShow=()=>{
		this.setState({ruleShow:false})
	}

	changeRules=()=>{
		this.setState({
			ruleShow:true
		})
	}

	componentDidMount(){
		this.props.queryInfo();
		this.props.getIndexImg();
		
	}
	componentWillUpdate(nextProps, nextState) {



		Util.fetchCallback({
            status: nextProps.Home.loginStatus,
            code: nextProps.Home.code,
            message: nextProps.Home.message,
            updateStatus: nextProps.updateStatus,
            successCallback: () => {
				this.setState({
					data:nextProps.Home.data
				})
				// let data = nextProps.Home.data;
				// if(data.mobile){
				// 	this.setState({
				// 		urlMobile:CryptoJS.AES.encrypt(data.mobile, 'secret key cdp666').toString()
				// 	})
				// }
            }
		});

		Util.fetchCallback({
            status: nextProps.Home.indexImgStatus,
            code: nextProps.Home.indexImgCode,
            message: nextProps.Home.indexImgMessage,
            updateStatus: nextProps.updateGetIndexImgStatus,
            successCallback: () => {
				
				let data = nextProps.Home.indexImgData;
				let newData = [];

				if(Object.prototype.toString.call(data) === '[object Array]' && data.length){
					data.forEach((item,index,arr)=>{
						if(item.urlImg && item.urlImg.indexOf('http') !== -1){
							newData.push(item);
						}
					})
				}
				
				this.setState({
					indexImgArr:newData
				})
            }
		});
		
	}

	renderBannerFunc=(imgArr)=>{
		if(imgArr.length){
			
			return (
				
                	imgArr.map((item,index)=>{
						return (
							<a key={index} href={item.jumpPage ? item.jumpPage : "javascript:;"}>
								<img src={item.urlImg ? item.urlImg : act2}/>
							</a>
						)
					})
                
			)

		}else {
			return (
				<a href="https://zt.web.bnq.com.cn/app/0507.html?name=FWH">
					<img src={act2}/>
				</a>
			)
		}	
		
	}
	render() {
		const imgData=[{
			title:"促销海报",
			src:tab1,
			href:"poster"
		},{
			title:"完善信息",
			src:tab2,
			href:"home"
		},
		{
			title:"优惠券",
			src:tab3,
			href:"coupon"
		}];
		return (
			<div>
			   <div className="card_con">
				   <img src={this.state.data.twentyAnniversaryFlag ? carBg2 : carBg} alt=""/>
				   <div className="card_img_item">
					 <img className="click-hide-qrcode ini" src={"/wx-web/public/web/getQrcode.do?content="+this.state.data.ecardCode} onClick={this.changeShow} style={{display: !this.state.isShow? "block" : "none", width:"85%",margin:'20px auto'}}/>
				   </div>
				   <div className="card_img_mess">
				      <div>
                        <span className="phone_num">{this.state.data.mobile}</span>
                      </div>
					  <div className="infos_message">
                        <div>
                          <span className="phone_num">
                             会员卡:
                             <span>{this.state.data.ecardCode}</span>
                          </span>
                        </div>
                        <div style={{marginLeft:'10px'}}>
                          <span className="phone_num">
                             开卡日期:
                            <span>{this.state.data.registerTimeStr}</span>
                         </span>
                       </div>
					   <div className='dp-addCard-div'>
						   <a href='https://mp.weixin.qq.com/bizmall/activatemembercard?action=preshow&&encrypt_card_id=pQ1Q6RoaucdSpVcbyYQ2qGzvQe2zDL1i6bVdfPMW658i%2BWcoZ9deqF6fOcnnEv25&outer_str=BAJ&biz=MzA4MTQyNDE3Mw%3D%3D#wechat_redirect'>
						   	<img src={cardBag} alt=""/>
							<span className='dp-addCard'>添加到卡包</span>
						   </a>
					   </div>
                      </div>
				   </div>

				   <div className='inte_rule' onClick={this.changeShow}>电子会员卡</div>
				   
				   <WingBlank>
				   <Modal
					visible={this.state.ruleShow}
					transparent
					maskClosable={false}
					onClose={this.rulesNotShow}
					title="积分规则"
					footer={[{ text: '确定', onPress: () => { this.rulesNotShow()} }]}
					
					>
					<div style={{ height: 150, overflow: 'scroll',textAlign:'left' }}>
						1、购物积分：会员在百安居线下门店购买指定商品，实付金额每10元可累积1积分（定制类商品、促销商品、特例品以及脱排油烟机/墙面漆/连体座厕/燃气热水器/陶瓷件套餐这些子类商品不参与积分，详见店内公示）；会员使用优惠券等享受的优惠金额、使用积分而减去的金额以及使用家装卡支付的金额，均不可进行积分。如已进行积分的订单发生退货时，按退货完成后相应订单最终的实付金额重新计算并调整已经计入的积分。
						<br/><br/> 2、积分可在百安居直接折抵顾客的消费应付金额，每100积分可折抵人民币1元。会员在百安居购物时选择积分支付，即可抵扣相应的付款金额。购物所得积分如果发生退货,这部分积分会退回给百安居;如果顾客使用积分抵扣消费应付金额的订单产生退货，已抵扣积分会退回给顾客账户
						<br/><br/> 3、所有消费积分、赠送积分、活动积分，均由系统根据积分规则完成校验后进行发放。
						<br/><br/> 4、会员获得的积分有效期为1年，自发放之日起计算+365天，过期积分清零。
						<br/><br/> 5、享有消费获得多倍积分权益的会员，在其他消费获得多倍积分活动时，享受倍数高的积分活动
						<br/><br/> 6、详情请洽各百安居线下门店工作人员。
					</div>
        			</Modal>
				   </WingBlank>
			   </div>
			   <div className="member_con">
					<ul>
						<li>
							<b>{this.state.data.customerLevelName}</b>
							<span>会员等级</span>
						</li>
						<li>
							<b>{this.state.data.usablePoints}</b>
							<span>我的积分</span>
						</li>
						<li>
							<b>{this.state.data.growth}</b>
							<span>成长经验</span>
						</li>
					</ul>
			   </div>
			   <div className="home-bottom">
				   <Bar imgSrc={tab1} title="促销海报" href="poster"/>
				   <Bar imgSrc={tab2} title="完善信息" href="information"/>
				   <Bar imgSrc={tab3} title="优惠券" href="coupon"/>
					{/* {
						imgData.map((item,i) => (
                           <Bar key={i} imgSrc={item.src} title={item.title} href={item.href}/>   
                        ))
					} */}
				</div>

				<div style={{textAlign:'center',marginBottom:'10px',marginTop:'63px',fontSize:'14px'}}>
					<span style={{textDecoration:'underline'}} onClick={this.changeRules}>积分规则</span>	
				</div>	
			   
			   <div className="banner_con">
                  <div className="banner_item">
					<Carousel>
						{
							this.renderBannerFunc(this.state.indexImgArr)
						}
					</Carousel>
                  </div>
                </div>
			   <div className='dp-empty'></div>	
			</div>
		);
	}
}

export default connect(
	(state) => {
		return {Home: state.Home}
	},
	(dispatch) => bindActionCreators({...actions}, dispatch)
)(Home);
import React from "react";
import { connect } from "react-redux";
import { List, Icon } from "antd-mobile"
import { AppBar } from "../components";

import classNames from "@/util/classNames";
import { diffTime } from "@/util";
import "./index.less";
const prefix = "park-record-detail";
const cx = classNames(prefix);

class RecordDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }
  componentDidMount = async () => {
    const { id } = this.props.match.params;

    const json = await this.props.handleHistoryDetail(id);
    const { result: { data } = {} } = json || {};

    this.setState({ data });
  };
  render() {
    const { data } = this.state;
    return (
      <div className={cx("wrap")}>
        <AppBar title="缴费详情" />
        {this.props.loading ? (
          <div className={cx("loading-wrap")}>
            <Icon type="loading" size="md" />
            <p>加载中...</p>
          </div>
        ) : (
            <List className={cx("content")}>
              <List.Item extra={data.carNumber}>车牌号</List.Item>
              <List.Item extra={data.shopName}>门店名称</List.Item>
              <List.Item extra={data.orderNo}>订单号</List.Item>
              <List.Item extra={data.startTimeStr}>入场时间</List.Item>
              <List.Item extra={data.endTimeStr}>离场时间</List.Item>
              <List.Item extra={diffTime(data.endTime, data.startTime)}>时长</List.Item>
              <List.Item extra={`${data.discountTimeStr}小时`}>抵扣时长</List.Item>
              <List.Item extra={`${data.amount}元`}>实际支付金额</List.Item>
            </List>
          )}
      </div>
    );
  }
}

const key = "parkNew";
export default connect(
  (state) => {
    return {
      loading: state.loading.effects[key].historyDetail,
    };
  },
  (dispatch) => {
    return {
      handleHistoryDetail: dispatch[key].historyDetail,
    };
  }
)(RecordDetail);


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InputItem, WhiteSpace, Button, WingBlank } from 'antd-mobile';
import { createForm } from 'rc-form';
import * as actions from './index.action';
import * as Util from "../../util/index";
import Toast from '../../components/prompt/toast';
import './index.less';

let flag = true;

class ExchangeCoupon extends Component {
	constructor(props) {
		super(props);
	}

	componentWillUpdate(nextProps, nextState) {
		Util.fetchCallback({
			status: nextProps.ExchangeCoupon.status,
			code: nextProps.ExchangeCoupon.code,
			message: nextProps.ExchangeCoupon.message,
			isShowToastSuccess: true,
			updateStatus: nextProps.updateGetExchangeCouponStatus,
			successCallback: () => {
				flag = true;
			},
			failCallback: () => {
				flag = true;
			}
		});
	}

	submit = () => {
		if (flag) {
			flag = false;
			let value = this.props.form.getFieldsValue();
			this.props.getExchangeCoupon(value);
		}

	}

	render() {
		const { getFieldProps } = this.props.form;
		return <div>
			<h3 className="exchange_coupon_title">兑换优惠券</h3>
			<WingBlank>
				<InputItem
					placeholder='请输入兑换码'
					{...getFieldProps('coupon')}
				></InputItem>
				<WhiteSpace></WhiteSpace>
				<Button type="primary" onClick={this.submit}>兑换</Button>
			</WingBlank>

		</div>
	}
}

export default connect(
	(state) => {
		return { ExchangeCoupon: state.ExchangeCoupon }
	},
	(dispatch) => bindActionCreators({ ...actions }, dispatch)
)(createForm()(ExchangeCoupon));
let initState = {

}
export default function (state = initState, action) {
	switch (action.type) {
		case 'GET_EXCHANGE_COUPON':
			return {
				...state,
				status: true,
				code: action.playload.code,
				message: action.playload.message,
				data: action.playload.data
			}
			break;
		case 'GET_EXCHANGE_COUPON_FAIL':
			return {
				...state,
				status: true,
				code: action.playload.code,
				message: action.playload.message,
				data: action.playload.data
			}
			break;
		case 'UPDATE_GET_EXCHANGE_COUPON_STATUS':
			return {
				...state,
				status: false
			}
			break;
		default:
			return state;
	}
}
const url = {
  development: {
    targetUrl: "http://wxadmin-test.market.bnq.com.cn",
    // targetUrl: "http://market-test.bnq.com.cn",
    // apiUrl: 'http://web.futureshop.dev-zt.bnq.com.cn:8080',
    // apiUrl: 'http://192.168.111.157:8080',
    apiUrl: "http://park.bnq.com.cn:8080",
    // apiUrl: "http://m-dev.market.bnq.com.cn",
    port: 8080,
    autoOpenBrowser: true,
    proxyFilter: "/wx-web",
    apiUrlFilter: "/wx-web",
    serviceFilter: "/wx-service",

    proxyInfo: {
      park: {
        context: "/carms-admin",
        target: "https://carms-dev.bnq.com.cn",
      },
    },
		himikDomain: 'https://himiko-dev.bthome.com',
  },
  productionUat: {
    targetUrl: "http://wxadmin-uat.market.bnq.com.cn",
    apiUrl: "http://m-uat.market.bnq.com.cn",
    apiUrlFilter: "/wx-web",
    serviceFilter: "/wx-service",
    port: 8080,
		himikDomain: 'https://himiko-uat.bthome.com',
    proxyInfo: {
      park: {
        context: "/carms-admin",
        target: "https://carms-dev.bnq.com.cn",
      },
    },
  },
  production: {
    targetUrl: "http://wxadmin.market.bnq.com.cn",
    apiUrl: "http://m.market.bnq.com.cn",
    proxyFilter: "/wx-web",
    apiUrlFilter: "/wx-web",
    serviceFilter: "/wx-service",
    port: 8080,
		himikDomain: 'https://himiko.bthome.com',
    proxyInfo: {
      park: {
        context: "/carms-admin",
        target: "https://carms.bnq.com.cn",
      },
    },
  },
  //线上test环境
  productionTest: {
    targetUrl: "http://wxadmin-test.market.bnq.com.cn",
    apiUrl: "http://m-test.market.bnq.com.cn",
    // apiUrl: 'http://web.futureshop.dev-zt.bnq.com.cn:8080',
    proxyFilter: "/wx-web",
    apiUrlFilter: "/wx-web",
    serviceFilter: "/wx-service",
    port: 8080,
		himikDomain: 'https://scgw-test.bthome.com',
    proxyInfo: {
      park: {
        context: "/carms-admin",
        target: "https://carms-test.bnq.com.cn",
      },
    },
  },
  setParkDomainConfig: (afferentDomain) => {
    if(!afferentDomain){
      return
    }
    const ENV = process.env.NODE_ENV;
    const parkTarget = url[ENV].proxyInfo.park.target;
    for (let newKey in afferentDomain) {
      if (afferentDomain.hasOwnProperty(newKey)) {
        if (parkTarget == newKey) {
          url[ENV].proxyInfo.park.target = `${afferentDomain[newKey]}`
          break
        }
      }
    }
  },
  getParkDomainConfig: () => {
    const ENV = process.env.NODE_ENV;
    return url[ENV].proxyInfo.park.target;
  },
};

module.exports = url;

import React from "react";

function debounce(fn, wait) {
  let task = undefined;

  return (...arg) => {
    if (task) {
      clearTimeout(task);
      task = undefined;
    }
    task = setTimeout(() => fn(...arg), wait);
  };
}

/**
 * 添加和移除scroll事件
 * 可选择性定义UI(可以不定义, 默认render是null)
 * 处理和接收一些通用的外部状态
 */
export default class extends React.Component {
  handleFetchMore = debounce((e) => {
    const { offsetHeight, scrollTop, scrollHeight } = e.target;

    const isReached = scrollHeight - offsetHeight - scrollTop < 70;

    const { isLoading, isLoadingMore, page, handle } = this.props;

    const { curPage, totalPage } = page;

    if (
      isReached &&
      isLoading === false &&
      isLoadingMore === false &&
      curPage < totalPage
    ) {
      const { state, setState, next } = handle;
      setState(state, debounce(next, 1000 * 3));
    }
  }, 1000 * 0.2);

  componentDidMount = () => {
    document.body.addEventListener("scroll", this.handleFetchMore);
  };

  componentWillUnmount = () => {
    document.body.removeEventListener("scroll", this.handleFetchMore);
  };

  render() {
    const {
      isLoading,
      isLoadingMore,
      page,
      list,
      components = {},
    } = this.props;

    const { curPage, totalPage } = page;

    const {
      loading = null,
      loadingMore = null,
      empty = null,
      noMore = null,
    } = components;

    if (isLoading) {
      return loading;
    }

    if (isLoadingMore) {
      return loadingMore;
    }

    if (list.length === 0) {
      return empty;
    }

    if (curPage >= totalPage) {
      return noMore;
    }

    return null;
  }
}

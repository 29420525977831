let initState = {
	
}
export default function(state=initState, action) {
	switch(action.type) {
		case 'GET_COUPON_INFO':
			return {
				...state,
				status:true,
				code: action.playload.code,
				message: action.playload.message,
				data:action.playload.data
			}
			break;
		case 'GET_COUPON_FAIL':
			return {
				...state,
			    status:false,
				code: action.playload.code,
				message: action.playload.message,
				data:action.playload.data
			}
			break;					
		default:
			return state;
	}
}
// import Toast from '../components/prompt/toast'
import { Toast } from 'antd-mobile';
const fetchCallback = (argus) => {
    const { status, code, message, params, updateStatus, successCallback, isShowToastSuccess, successText, isShowDialog, failCallback, isNotReplaceState } = argus;
    console.log(status, 'mmmm')
    if (status) {
        updateStatus();

        // //test
        // successCallback && successCallback();
        // return;
        if (code && code !== 0) {
            if (code >= 500) {
                Toast.fail('服务器异常');
            } else if (code >= 400) {
                if (code == 404) {
                    Toast.fail('服务器找不到请求地址');
                } else if (code == 414) {
                    Toast.fail('请求的 URI（通常为网址）过长，服务器无法处理');
                } else {
                    Toast.fail('错误请求');
                }
            } else if (code >= 300) {
                Toast.fail('网络异常');
            } else if (code == -1) {
                //优惠券没有登录
                let currentUrl = location.href;
                let currentHost = location.origin;
                window.location.href = currentHost + "/?toUrl=" + currentUrl
            } else if (code == 200) {
                //微信三方登陆
                isShowToastSuccess ? Toast.success(successText || message) : null;
                successCallback && successCallback();
            } else {
                failCallback && failCallback();
                Toast.fail(message);
            }
        } else if (code === 0) {
            isShowToastSuccess ? Toast.success(successText || message) : null;

            successCallback && successCallback();
        }
    }
}
function createUrl(request) {
    let url = request.url
    let param = request.param

    if (param) {
        url = !url.includes('?') && url + '?'
        for (let key of Object.keys(param)) {
            url = url + key + '=' + param[key] + '&'
        }
        if (url.endsWith('&')) {
            url = url.substring(0, url.length - 1)
        }
    }
    return url
}

function setCookie(name, value) {
    let days = 30;
    let exp = new Date();
    exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString()
}

function getCookie(name) {
    let arr = document.cookie.match(new RegExp('(^| )' + name + '=([^;]*)(;|$)'))
    return arr !== null ? unescape(arr[2]) : null
}

function getNumberRemoveDouble(num) {
    let t1, r1;
    try {
        t1 = num.toString().split('.')[1].length;
    } catch (e) {
        t1 = 0;
    }
    r1 = Number(num.toString().replace(".", ""));
    return r1 * Math.pow(10, -t1)
}

function getUrlArg(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var arg = window.location.search.substr(1).match(reg);
    if (arg) {
        return arg[2]
    } else {
        return ''
    }
}

function formatNum(str) {
    var newStr = "";
    var count = 0;
    // 当数字是整数
    if (str.indexOf(".") == -1) {
        for (var i = str.length - 1; i >= 0; i--) {
            if (count % 3 == 0 && count != 0) {
                newStr = str.charAt(i) + "," + newStr;
            } else {
                newStr = str.charAt(i) + newStr;
            }
            count++;
        }
        return newStr;
    }
    // 当数字带有小数
    else {
        for (var i = str.indexOf(".") - 1; i >= 0; i--) {
            if (count % 3 == 0 && count != 0) {
                newStr = str.charAt(i) + "," + newStr;
            } else {
                newStr = str.charAt(i) + newStr; //逐个字符相接起来
            }
            count++;
        }
        str = newStr + (str + "00").substr((str + "00").indexOf("."), 3);
        let lineIndex = str.indexOf('-');
        let dIndex = str.indexOf(',');
        if (lineIndex !== -1 && lineIndex === (dIndex - 1)) {
            str = str.replace(/-,/, '-');
        }
        return str;
    }
}

function diffTime(from, to, initTimeDiff = 0) {

    const timeDiff = initTimeDiff ? initTimeDiff : Math.abs(to - from); // 毫秒差

    const days = Math.floor(timeDiff / (24 * 3600 * 1000)); // 计算出天数

    const leavel1 = timeDiff % (24 * 3600 * 1000); // 计算天数后剩余的时间
    const hours = Math.floor(leavel1 / (3600 * 1000)); // 计算天数后剩余的小时数

    const leavel2 = leavel1 % (3600 * 1000); // 计算剩余小时后剩余的毫秒数
    const minutes = Math.floor(leavel2 / (60 * 1000)); // 计算剩余的分钟数

    const leavel3 = leavel2 % (60 * 1000)
    const seconds = Math.round(leavel3 / 1000);

    const arr = [
        { name: '天', value: days },
        { name: '小时', value: hours },
        { name: '分', value: minutes },
        { name: '秒', value: seconds },
    ]

    let str = ''
    arr.filter(item => item.value !== 0)
        .map(i => {
            str += `${i.value}${i.name}`
        })
    return str
}

const isInMiniProgram = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf('miniprogram') !== -1;
}


export {
    createUrl,
    setCookie,
    getCookie,
    getNumberRemoveDouble,
    getUrlArg,
    formatNum,
    fetchCallback,
    diffTime,
    isInMiniProgram
}
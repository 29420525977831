import React from "react";
import Proptypes from "prop-types";
import { Icon, Button } from "antd-mobile";
import Keyboard from "./Keyboard";

import "./index.less";
import classNames from "@/util/classNames";
const prefix = "park-car-input-keyboard";
const cx = classNames(prefix);

const SwitchInputBase = ({ simulate, inputProps }, ref) => {
	const { value, placeholder = "" } = inputProps;
	if (simulate) {
		const numberPlateArr = value.split("")
		const gridArr = Array.from({ length: 8 }, (v, k) => k)
		return (
			<div className={cx("input", "simulate", { "input-empty": value === "" })}>
				{/* {value === "" ? placeholder : value} */}
				{
					gridArr.map((i, idx) => <span
						key={i}
						style={{backgroundColor: '#F5F5F5', marginLeft: idx === 2 ? '12px' : ''}}
						className={numberPlateArr.length === idx || (numberPlateArr.length === 8 && idx === 7) ? "active" : ""}
					>
						{i === 2 && <div style={{backgroundColor: '#F5F5F5', width: '6px', height: '6px', borderRadius: '3px', position: 'absolute', top: "16px", left: '-12px' }}></div>}
						{numberPlateArr[i]}{i === 7 && !numberPlateArr[i] && <div></div>}
					</span>)
				}
			</div>
		);
	}
	return <input ref={ref} className={cx("input")} {...inputProps} maxLength="8" />;
};

const SwitchInput = React.forwardRef(SwitchInputBase);

export default class CarInputPanel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			simulateInput: true,
		};

		this.inputRef = React.createRef();
	}
	static propTypes = {
		/**
		 * 不要与 inputProps里的 onChange 混淆
		 */
		onChange: Proptypes.func.isRequired,

		// 提交的结果 可以是 inputProps 里面的 value
		inputProps: Proptypes.object,

		btnProps: Proptypes.object,
		btnText: Proptypes.string.isRequired,
	};

	static defaultProps = {
		inputProps: {
			onChange: () => { },
			value: "",
		},
		btnProps: {},
	};

	componentDidUpdate = (_, prevState) => {
		const { simulateInput } = this.state;

		const { simulateInput: prevSimulateInput } = prevState;

		if (simulateInput !== prevSimulateInput) {
			if (simulateInput === false) {
				this.inputRef.current.focus();
			}
		}
	};

	toggleSimulateInput = () => {
		this.setState((prev) => ({
			...prev,
			simulateInput: !prev.simulateInput,
		}));
	};

	render() {
		const { inputProps, onChange, btnProps, btnText, title } = this.props;

		const { value } = inputProps;
		const { simulateInput } = this.state;
		const { disabled } = btnProps

		return (
			<div className={prefix}>
				<Keyboard value={value} onChange={onChange} />
				<div className={cx("content")}>
					<p className="parkcommon-tip-title">{title || '请输入您要绑定的车牌：'}</p>
					<div className={cx("up")}>
						<div className={cx("input-wrap")}>
							<SwitchInput
								ref={this.inputRef}
								simulate={simulateInput}
								inputProps={{
									...inputProps,
									// 覆盖 原始 onChange
									onChange: (e) => onChange(e.target.value),
								}}
							/>
							{/* {inputProps.value !== "" && (
                <Icon
                  size="xs"
                  className={cx("clear-icon")}
                  type="cross-circle-o"
                  onClick={() => {
                    onChange("");
                  }}
                />
              )} */}
						</div>
					</div>
					<Button
						className="park-car-input-keyboard-btn  parkcommon-am-button "
						{...btnProps}
						loading={disabled}
						size="lg"
					>
						{btnText}
					</Button>
				</div>
			</div >
		);
	}
}

import request from "./request";
import { prizeMayAuth } from "./storageManager";

export const lotteryInfoApi = async () => {
  let url = "/wx-web/public/thw/lottery/info";

  const { phone, token } = prizeMayAuth.getItem() || {};

  url = `${url}?phone=${phone}&token=${token}`;

  try {
    return await request(url);
  } catch (error) {
    throw error;
  }
};

export const lotteryFinalApi = async () => {
  let url = "/wx-web/public/thw/lottery";
  const { phone, token } = prizeMayAuth.getItem() || {};

  url = `${url}?phone=${phone}&token=${token}`;

  try {
    return await request(url);
  } catch (error) {
    throw error;
  }
};

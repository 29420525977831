let initState = {
	
}
export default function(state=initState, action) {
	switch(action.type) {
		case 'LOGIN_USER':
			return {
				...state,
				loginStatus: true,
				loginCode: action.playload.code,
				loginMessage: action.playload.message
			}
			break;
		case 'LOGIN_USER_FAIL':
			return {
				...state,
				loginStatus: true,
				loginCode: action.playload.code,
				loginMessage: action.playload.message
			}	
			break;
		case 'UPDATE_LOGIN_USER_STATUS':
			return {
				...state,
				loginStatus: false
			}
			break;
		case 'WX_LOGIN_USER':
			return {
				...state,
				wxLoginStatus: true,
				wxLoginCode: action.playload.code,
				wxLoginMessage: action.playload.message,
				wxLoginData:action.playload.data
			}
			break;
		case 'UPDATE_WX_LOGIN_USER_STATUS':
			return {
				...state,
				wxLoginStatus: false
			}
			break;		
		case 'GET_CODE':
		    return {
				...state,
				verifyStatus:true,
				verifyCode: action.playload.code,
				verifyMessage: action.playload.message
			}	
		case 'GET_CODE_FAIL':
		    return {
				...state,
				verifyStatus:true,
				verifyCode: action.playload.code,
				verifyMessage: action.playload.message
			}
		case 'UPDATE_GET_CODE_STATUS':
		    return {
				...state,
				verifyStatus:false
            }	
        case 'WECHART_LOGIN':
		    return {
				...state,
				wechartLoginStatus:true,
				wechartLoginCode: action.playload.code,
				wechartLoginData: action.playload.data
			}	
		case 'WECHART_LOGIN_FAIL':
		    return {
				...state,
				wechartLoginStatus:true,
				wechartLoginCode: action.playload.code,
				wechartLoginMessage: action.playload.message
			}
		case 'UPDATE_WECHART_LOGIN_STATUS':
		    return {
				...state,
				wechartLoginStatus:false
			}	    		
		default:
			return state;
	}
}
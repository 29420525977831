let initState = {

}
export default function (state = initState, action) {
	switch (action.type) {

		case 'GET_USER_INFO':
			return {
				...state,
				getUserInfoStatus: true,
				userInfoCode: action.playload.code,
				userInfoMessage: action.playload.message,
				userInfoData: action.playload.data,
			}
			break;
		case 'UPDATE_GET_USER_INFO_STATUS':
			return {
				...state,
				getUserInfoStatus: false,
			}
			break;
		case 'GET_USER_INFO_FAIL':
			return {
				...state,
				getUserInfoStatus: true,
				userInfoCode: action.playload.code,
				userInfoMessage: action.playload.message,
				userInfoData: action.playload.data,
			}
			break;
		case 'GET_ENUMS':
			return {
				...state,
				getEnumsStatus: true,
				getEnumsCode: action.playload.code,
				getEnumsMessage: action.playload.message,
				getEnumsData: action.playload.data
			}
			break;
		case 'UPDATE_GET_ENUMS_STATUS':
			return {
				...state,
				getEnumsStatus: false
			}
			break;
		case 'GET_ENUMS_FAIL':
			return {
				...state,
				getEnumsStatus: true,
				getEnumsCode: action.playload.code,
				getEnumsMessage: action.playload.message,
				getEnumsData: action.playload.data
			}
			break;
		case 'GET_TAGS':
			return {
				...state,
				getTagsStatus: true,
				getTagsCode: action.playload.code,
				getTagsMessage: action.playload.message,
				getTagsData: action.playload.data
			}
			break;
		case 'UPDATE_GET_TAGS_STATUS':
			return {
				...state,
				getTagsStatus: false
			}
			break;
		case 'GET_TAGS_FAIL':
			return {
				...state,
				getTagsStatus: true,
				getTagsCode: action.playload.code,
				getTagsMessage: action.playload.message,
				getTagsData: action.playload.data
			}
			break;

		case 'SUBMIT_DATA':
			return {
				...state,
				submitDataStatus: true,
				submitDataCode: action.playload.code,
				submitDataMessage: action.playload.message,
				submitDataData: action.playload.data
			}
			break;
		case 'UPDATE_SUBMIT_DATA_STATUS':
			return {
				...state,
				submitDataStatus: false
			}
			break;
		case 'SUBMIT_DATA_FAIL':
			return {
				...state,
				submitDataStatus: true,
				submitDataCode: action.playload.code,
				submitDataMessage: action.playload.message,
				submitDataData: action.playload.data
			}
			break;
		case 'GET_ALL_ADDRESS':
			return {
				...state,
				allAddressStatus: true,
				allAddressCode: action.playload.code,
				allAddressMessage: action.playload.message,
				allAddressData: action.playload.data
			}
			break;
		case 'UPDATE_GET_ALL_ADDRESS_STATUS':
			return {
				...state,
				allAddressStatus: false
			}
			break;
		case 'GET_ALL_ADDRESS_FAIL':
			return {
				...state,
				allAddressStatus: true,
				allAddressCode: action.playload.code,
				allAddressMessage: action.playload.message,
				allAddressData: action.playload.data
			}
			break;
		case 'GET_STREET_ADDRESS':
			return {
				...state,
				streetAddressStatus: true,
				streetAddressCode: action.playload.code,
				streetAddressMessage: action.playload.message,
				streetAddressData: action.playload.data
			}
			break;
		case 'UPDATE_GET_STREET_ADDRESS_STATUS':
			return {
				...state,
				streetAddressStatus: false
			}
			break;
		case 'GET_STREET_ADDRESS_FAIL':
			return {
				...state,
				streetAddressStatus: true,
				streetAddressCode: action.playload.code,
				streetAddressMessage: action.playload.message,
				streetAddressData: action.playload.data
			}
			break;
		default:
			return state;
	}
}
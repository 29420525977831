import remote from "./request";
import {
    info as infoStorage,
    parkCurShop as parkCurShopStorage,
} from "@/util/storageManager";

export const carBindAPI = async (body) => {
    const url = "/member/car/bind.do";
    const { shopNo } = parkCurShopStorage.getItem() || {};
    body.shopNo = shopNo

    const json = await remote.post(url, { body, isNeedMemberPhone: true });
    return json;
};

export const carUpdateAPI = async (body) => {
    const url = "/member/car/update.do";
    const { shopNo } = parkCurShopStorage.getItem() || {};
    body.shopNo = shopNo

    const json = await remote.post(url, { body });
    return json;
};

export const carDeleteAPI = async (id) => {
    const url = `/member/car/delete.do`;
    return await remote.get(url, { id });
};

export const carDetailByIdAPI = async (id) => {
    const url = `/member/car/detail.do`;
    return await remote.get(url, { id });
};


import a50 from "./images/prize/a50.png";
import chuifeng from "./images/prize/chuifeng.png";
// import dai from "./images/prize/dai.png";
import kaolu from "./images/prize/kaolu.png";
import mofei from "./images/prize/mofei.png";

const PRIZE = {
  "2020051501": {
    name: "戴森",
    pos: 0, //转盘的位置  number or array
    img: undefined, // 奖品的名称 和 images/prize 命名一致
  },
  "2020051502": {
    name: "飞利浦电炙烤炉",
    pos: 1,
    img: kaolu,
  },
  "2020051503": {
    name: "摩飞果汁杯礼券",
    pos: 4,
    img: mofei,
  },
  "2020051504": {
    name: "飞利浦电吹风",
    pos: 3,
    img: chuifeng,
  },
  "2020051505": {
    name: "百安居50元礼券",
    pos: [2, 5],
    img: a50,
  },
};

export const getPos = (key) => {
  const pos = (PRIZE[key] && PRIZE[key].pos) || 0;

  if (Array.isArray(pos)) {
    const len = pos.length;
    const ran = Math.floor(Math.random() * len);
    return pos[ran];
  }

  return pos;
};

export default PRIZE;

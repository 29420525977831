export default function(state={}, action) {
	switch(action.type) {
		case 'GET_CAROUSEL':
			return {
				...state,
				posterStatus: true,
				posterCode: action.playload.code,
				posterData:action.playload.data,
				posterMessage: action.playload.message
			}
			break;
		case 'GET_CAROUSEL_FAIL':
			return {
				...state,
				posterStatus: true,
				posterCode: action.playload.code,
				posterMessage: action.playload.message
			}	
			break;
		case 'UPDATE_GET_CAROUSEL_STATUS':
			return {
				...state,
				posterStatus: false
			}
			break;
		default:
			return state;
	}
}
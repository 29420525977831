import * as Util from "../../util/index";
import md5 from "js-md5";
import moment from "moment";

const DEFAULT_OPTIONS = {
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
};

const parseJSON = (response) => {
  return response.json();
};

const request = (url, options = {}) => {
  return fetch(url, { ...DEFAULT_OPTIONS, ...options }).then(parseJSON);
};

export const login = async () => {
  const url = `/wx-web/public/wx/login`;

  const openid = Util.getUrlArg("key");

  const str = "vkhtbhm5u23u4f50rcdv0mo8l5444";
  const time = moment().format("YYYYMMDD");
  const sign = md5(openid + str + time);

  const options = {
    method: "POST",
    body: JSON.stringify({ data: { openid, sign } }),
  };
  return await request(url, options);
};

export const queryInfo = async () => {
  const url = `/wx-web/web/queryInfo.do`;

  return await request(url);
};

// 等比例缩放 切不超过max的范围
export const calcImg = (max, natural) => {
  const [mw, mh] = max;
  const [nw, nh] = natural;

  const r = nw / nh;

  let w = mw < nw ? mw : nw;

  let h = w / r;

  const finaH = mh < nh ? mh : nh;

  if (h > finaH) {
    h = finaH;
    w = h * r;
  }

  return [w, h];
};

export default {
  title: [711, 533],
  titleBottom: 180,
  main: [658, 658],
  go: [166, 189],
};

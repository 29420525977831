let initState = {
	
}
export default function(state=initState, action) {
	switch(action.type) {
		case 'EDIT_BINDPHONE':
			return {
				...state,
				editPhoneStatus: true,
				editPhoneCode: action.playload.code,
				editPhoneMessage: action.playload.message,
				editPhoneData:action.playload.data
			}
			break;
		case 'EDIT_BINDPHONE_FAIL':
			return {
				...state,
				editPhoneStatus: true,
				editPhoneCode: action.playload.code,
				editPhoneMessage: action.playload.message
			}	
			break;
		case 'UPDATE_EDIT_BINDPHONE':
			return {
				...state,
				editPhoneStatus: false
			}
			break;
		case 'GETCOMPANYENUM':
			return {
				...state,
				getCompanyStatus: true,
				getCompanyCode: action.playload.code,
				getCompanyMessage: action.playload.message,
				getCompanyData:action.playload.data
			}
			break;
		case 'GETCOMPANYENUM_FAIL':
			return {
				...state,
				getCompanyStatus: true,
				getCompanyCode: action.playload.code,
				getCompanyMessage: action.playload.message
			}	
			break;
		case 'UPDATE_GETCOMPANY':
			return {
				...state,
				getCompanyStatus: false
			}
			break;
		default:
			return state;
	}
}
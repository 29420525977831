import remote from "./request";

import { info as infoStorage } from "@/util/storageManager";

// curPage pageSize shopNo memberPhone
export const historyListAPI = async (body) => {
    const url = "/member/car/history.do";
    return await remote.post(url, { body, isNeedMemberPhone: true });
};

export const historyDetailAPI = async (id) => {
    const url = `/member/car/historyDetail.do`;
    return await remote.get(url, { id });
};

export const historyParksAPI = async () => {
    const { mobile = undefined } = infoStorage.getItem() || {};
    const url = `/parkCouponConfig/findShopList.do?memberPhone=${mobile}`
    return await remote.get(url, {});
};


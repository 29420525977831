import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Util from '../../util/index';
import * as actions from './index.action';
import './index.less';

class Wxscan extends Component {
    componentWillMount(){
        this.props.wxGetOpenid();
    }

    componentWillUpdate (nextProps, nextState) {
        Util.fetchCallback({
            status: nextProps.Wxscan.authStatus,
            code: nextProps.Wxscan.authCode,
            message: nextProps.Wxscan.authMessage,
            updateStatus: nextProps.updateGetOpenidStatus,
            successCallback: () => {
                let myData = nextProps.Wxscan.authData;
                window.location.href = myData; 
            }
        });
    }

    render() {
		return (
			<div></div>
        )
    }
}

export default connect(
	(state) => {
		return {Wxscan: state.Wxscan,FetchState: state.Fetch}
	},
	(dispatch) => bindActionCreators({...actions}, dispatch)
)(Wxscan);
// import { createStore, applyMiddleware } from "redux";
import rootReducers from "./rootReducers";
import fetchMiddleware from "./middleware/fetch/index";

// import { createLogger } from "redux-logger";

// const params = [fetchMiddleware];

// const ENV = process.env.NODE_ENV || "production";

// if (ENV !== "production") {
//   const logger = createLogger();
//   params.push(logger);
// }

// const storeWithMiddleware = applyMiddleware(...params)(createStore);

// export default () => {
//   return storeWithMiddleware(rootReducers);
// };

import parkNew from "@/app/Park/parkApiModel/model"


import { init } from "@rematch/core";
import createLoadingPlugin from '@rematch/loading';
const loading = createLoadingPlugin({});

const store = init({
  models: {
    parkNew,
  },
  redux: {
    reducers: rootReducers,
    middlewares: [fetchMiddleware]
  },
  plugins: [loading],
});

export default store;

import { combineReducers } from "redux";
import Fetch from "./middleware/fetch/index.reducer";
import Home from "./app/home/index.reducer";
import Login from "./app/login/index.reducer";
import Information from "./app/information/index.reducer";
import Coupon from "./app/coupon/index.reducer";
import CouponQr from "./app/couponQr/index.reducer";
import ExchangeCoupon from "./app/exchangeCoupon/index.reducer";
import Poster from "./app/poster/index.reducer";

import Wxscan from "./app/wxscan/index.reducer";
import wxAdd from "./app/wxAdd/index.reducer";
import wxEdit from "./app/wxEdit/index.reducer";
import Show from "./app/show/index.reducer";

export default {
  Fetch,
  Login,
  Home,
  Information,
  Coupon,
  CouponQr,
  Poster,
  Wxscan,
  wxAdd,
  wxEdit,
  Show,
  ExchangeCoupon
};

// export default combineReducers({
//   Fetch,
//   Login,
//   Home,
//   Information,
//   Coupon,
//   CouponQr,
//   Poster,
//   Wxscan,
//   wxAdd,
//   wxEdit,
//   Show,
//   park,
//   ExchangeCoupon
// });



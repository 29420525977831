import {
  PARK_CUR_CAR,
  PARK_CUR_SHOP,
  SESSION,
  INFO,
} from "../constants/storage";
import Storage from "./storage";

export const parkCurCar = new Storage(PARK_CUR_CAR);
export const parkCurShop = new Storage(PARK_CUR_SHOP);
export const session = new Storage(SESSION);
export const info = new Storage(INFO);

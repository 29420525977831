const classNames = (prefix, { unprefix = 'global', separator = '-' } = {}) => {
    return (...params) => {
    // class is separated by a space
        const s = ' ';

        const hasOwn = {}.hasOwnProperty;

        const classes = [];

        const unprefixClasses = [];

        params.forEach((item) => {
            const itemType = typeof item;
            if (itemType === 'string' || itemType === 'number') {
                classes.push(item);
            } else if (Array.isArray(item)) {
                if (item.length) {
                    const inner = classNames()(...item);
                    if (inner) {
                        classes.push(...inner.split(s));
                    }
                }
            } else if (itemType === 'object') {
                if (item.toString !== Object.prototype.toString) {
                    classes.push(item.toString());
                } else {
                    for (const key in item) {
                        if (hasOwn.call(item, key) && item[key]) {
                            if (key === unprefix) {
                                const value = item[key];

                                if (value) {
                                    unprefixClasses.push(classNames()(value));
                                }
                            } else {
                                classes.push(key);
                            }
                        }
                    }
                }
            }
        });

        const prefixClasses = classes.map((item) => {
            if (prefix) {
                return `${prefix}${separator}${item}`;
            }
            return item;
        });

        return [...unprefixClasses, ...prefixClasses].join(s);
    };
};

export default classNames;

import React from "react";
import Proptypes from "prop-types";

import { Icon } from "antd-mobile";

import "./Keyboard.less";

import PROVINCE from "./province";
import city from "./city";

import classNames from "../../../../util/classNames";

const prefix = "park-car-input-panel-keyboard";

const cx = classNames(prefix);

const num = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

const LIST = {
  num,
  province: PROVINCE.map(({ name }) => name),
  city: city.map((item) => item.toUpperCase()),
};

const Tabs = ({ handleSelect, type, simulateInput, toggleSimulateInput }) => {
  return (
    <div className={cx("tabs")}>
      {simulateInput && type !== "province" && (
        <div
          className={cx({
            global: {
              select: type === "city",
            },
          })}
          onClick={() => handleSelect("city")}
        >
          ABC
        </div>
      )}
      {simulateInput && type !== "province" && (
        <div
          className={cx({
            global: {
              select: type === "num",
            },
          })}
          onClick={() => handleSelect("num")}
        >
          123
        </div>
      )}

      <div onClick={toggleSimulateInput}>
        {simulateInput ? "自带键盘" : "点击这里使用快捷键盘(推荐)"}
      </div>
    </div>
  );
};

export default class Keyboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "province",
    };
  }
  static propTypes = {
    onChange: Proptypes.func.isRequired,
    toggleSimulateInput: Proptypes.func,
  };

  static defaultProps = {
    toggleSimulateInput: () => {},
  };

  tabOnSelect = (type) => {
    this.setState({ type });
  };

  btnOnClick = (value) => {
    const { value: prevValue, onChange } = this.props;
    onChange(`${prevValue}${value}`);
  };

  handleDel = () => {
    const { value: prevValue, onChange } = this.props;
    const len = prevValue.length;
    const value = prevValue.slice(0, len - 1);
    console.log(value);
    onChange(value);
  };

  componentDidUpdate = (prevProps) => {
    const { value, simulateInput } = this.props;
    const { value: prevValue, simulateInput: prevSimulateInput } = prevProps;

    const { type } = this.state;

    if (value !== prevValue || simulateInput !== prevSimulateInput) {
      if (value === "") {
        this.setState({ type: "province" });
      }

      if (value.length > 0) {
        this.setState({ type: "city" });
      }

      if (value.length > 1) {
        this.setState({ type: "num" });
      }
    }
  };

  render() {
    const { value, toggleSimulateInput, simulateInput } = this.props;

    const { type } = this.state;

    const col = {
      province: "col-sm",
      city: "col-md",
      num: "col-lg",
    };
    return (
      <div className={prefix}>
        <Tabs
          simulateInput={simulateInput}
          type={type}
          handleSelect={this.tabOnSelect}
          toggleSimulateInput={toggleSimulateInput}
        />
        {simulateInput && (
          <div className={cx("list", col[type])}>
            {LIST[type].map((item) => (
              <div key={item} onClick={() => this.btnOnClick(item)}>
                {item}
              </div>
            ))}
            {value !== "" && (
              <div className={cx("del-btn")} onClick={this.handleDel}>
                删除
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

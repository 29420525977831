import React from "react";
import { Icon } from "antd-mobile";
import classNames from "@/util/classNames";
import { parkCurShop } from '@/util/storageManager';

import "./Item.less";
const prefix = "park-shop-item";
const cx = classNames(prefix);

export default ({
  data: { shopName, shopAddress, shopNo } = {},
  children,
  onClick,
}) => {
  const { shopNo: selectShopNo } = parkCurShop.getItem() || {};
  console.log("shopNo, selectShopNo", shopNo, selectShopNo);
  return (
    <li className={cx("wrap")} onClick={onClick}>
      <div style={{display: 'flex', flexDirection: 'column', flex: '1'}}>
        <div className={cx("name-col")}>
          <div className={cx("name")}>{shopName}</div>
          {children}
        </div>
        <div className={cx("address")}>{shopAddress}</div>
      </div>
      {shopNo === selectShopNo && (
        <div className={cx('right-icon')}></div>
      )}
    </li>
  );
};
